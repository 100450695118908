import { Fragment, useState } from 'react'
import ModalDropdown from './ModalDropdown';
import { containsSpecialChars } from '../../../../../CommonComponents/CommonFunctions';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface ScenarioDetailsProps {
    scenario_details: any;
    set_scenario_details: (details: Object) => void;
    navigate_scenario: (details: string) => void;
    data_submited: string[];
    true_twin: Object;
    scenario_step?: string;
    loading: boolean | string;
    uploaded_scenario: null | { name: string, file_data: any, file_uploaded: boolean };
    set_uploaded_scenario: (data: null | { name: string, file_data: any, file_uploaded: boolean }) => void;
}

const ScenarioDetails: React.FC<ScenarioDetailsProps> = ({ scenario_details, set_scenario_details, navigate_scenario, uploaded_scenario, set_uploaded_scenario, scenario_step, data_submited, loading }) => {
    const [searchParams] = useSearchParams();
    const upload_scenario = searchParams.get('upload_scenario');
    const navigate = useNavigate();

    const update_url_search_params = (event) => {
        event.stopPropagation();
        if (upload_scenario) {
            navigate(window.location.pathname)
        } else {
            const currentParams = new URLSearchParams(searchParams); // Create a copy
            currentParams.set('upload_scenario', 'true');
            navigate(`?${currentParams.toString()}`); // Navigate with updated params
        }
    };

    const upload_scenario_data = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = '.yml'; // Accept only YAML files

        fileInput.onchange = (event: any) => {
            const file = event?.target?.files[0];

            if (file) {
                // Check for valid YAML file extension
                if (!file.name.toLowerCase().endsWith('.yml')) {
                    toast.error("Invalid file format. Please upload a YAML file (must end with '.yml').");
                    return;
                }

                const reader = new FileReader();
                reader.onload = (e: any) => {
                    const uploadFile = e.target.result;
                    // Do something with the file content
                    const blob = new Blob([uploadFile], { type: "multipart/form-data" });
                    const file_data = new FormData();
                    file_data.append("file", blob, file.name);
                    set_uploaded_scenario({
                        file_data: file_data,
                        name: file.name,
                        file_uploaded: false
                    });
                };
                reader.readAsText(file);
            } else {
                toast.error("Invalid file format");
            }
        };

        fileInput.click();
    };

    return (
        <Fragment>
            <div className="modalBoxShadowDiv">
                <button
                    onClick={() => {
                        if (data_submited?.includes('Scenario_Details')) {
                            navigate_scenario('Scenario_Details');
                        }
                    }}
                    className={`d-flex w-100 py-2 pe-3 align-items-center justify-content-between bg-transparent ${(!data_submited?.includes('Scenario_Details') && scenario_step !== 'Scenario_Details') ? "disabledBtn" : ''}`}
                >
                    <div className="d-flex align-items-center gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d={scenario_step === 'Scenario_Details' ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                                stroke="#F0F0F0"
                                strokeWidth="1.25"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <div className="fs-14">Scenario Details</div>
                    </div>
                    <div className='d-flex align-items-center gap-3'>
                        <div className='d-flex gap-2 align-items-center'>
                            <button className={`upload__scenario__toggle__btn ${upload_scenario && 'active'}`}
                                onClick={update_url_search_params}
                            />
                            <span className='fs-14'>Upload</span>
                        </div>
                        {data_submited?.includes('Scenario_Details') && <img src={require("../Images/GreenTickIcon.svg").default} />}
                    </div>
                </button>
            </div>

            {scenario_step === 'Scenario_Details' && (
                <form id={`scenarioDetails ${loading && 'disable__components'}`}>
                    <div className="modalSubHeader modalAnalysisText">Scenario Title</div>
                    <input
                        value={scenario_details.scenarioName}
                        onChange={(e) => {
                            const trimmedValue = e.target.value.trimStart();
                            // Check if the input contains special characters
                            if (containsSpecialChars(trimmedValue)) {
                                toast.dismiss()
                                toast.error("Scenario title cannot contain special characters", { toastId: "error" });
                            }
                            // Check if the input length is within the allowed range
                            else if (trimmedValue.length <= 26) {
                                set_scenario_details({
                                    scenarioName: trimmedValue
                                });
                            }
                            // Allow deletion even if the current name exceeds 26 characters
                            else if (trimmedValue.length < scenario_details?.scenarioName?.length) {
                                set_scenario_details({
                                    scenarioName: trimmedValue
                                });
                            }
                            // Show error if the input exceeds the length limit
                            else {
                                toast.dismiss()
                                toast.error("Scenario title cannot exceed 26 characters", { toastId: "err" });
                            }
                        }}
                        className="modalInputFullLen text__input"
                        type="text"
                        placeholder="Scenario title"
                        required
                    />
                    {!upload_scenario &&
                        <Fragment>
                            <div className="modalSubHeader modalAnalysisText opacity-50">Time Scaling Factor</div>
                            <input
                                disabled
                                // value={scenario_details?.sampleRate}
                                value={'Real Time'}
                                onChange={(e) => {
                                    set_scenario_details({
                                        sampleRate: e.target.value
                                    })
                                }}
                                className="modalInputFullLen opacity-50 text__input"
                                type="text"
                                placeholder="Time scaling Factor"
                            />
                        </Fragment>
                    }
                    {upload_scenario &&
                        <div className='d-flex flex-column gap-3 px-3 pt-3'>
                            <button className='upload_scenario_btn' onClick={upload_scenario_data}> Upload Scenario</button>
                            {uploaded_scenario &&
                                <div className='w-100 p-0 ps-2 bg-black border d-flex align-items-center justify-content-between rounded-2 text-truncate'>
                                    <span className='w-90'>{uploaded_scenario?.['name']}</span>
                                    <button className='p-2 bg-transparent d-flex align-items-center justify-content-center'
                                        onClick={() => {
                                            set_uploaded_scenario(null)
                                        }}
                                    >
                                        <i className='fe fe-x fs-18' />
                                    </button>
                                </div>
                            }
                        </div>
                    }
                    {/* <div className="modalSubHeader modalAnalysisText opacity-50">Goal</div>
                    <div style={{ margin: "12px" }}>
                        <ModalDropdown options={[]} disabled={true} handleSelectedOption={()=>{}} selectedOption={null} placeholder='Goal'/>
                    </div>
                    <div className="modalSubHeader modalAnalysisText opacity-50">Objective</div>
                    <div style={{ margin: "12px" }}>
                        <ModalDropdown options={[]} disabled={true} handleSelectedOption={()=>{}} selectedOption={null} placeholder='Obective'/>
                    </div> */}
                </form>
            )}
        </Fragment>
    )
}

export default ScenarioDetails
