
import { toast } from 'react-toastify';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import useOrbitParams from '../../hooks/useOrbitParams';
import moment from 'moment';
import { EditTleSvg, SaveTleSvg, TleHistorySvg } from './Svgs';
import './Header.scss';
import CustomToolTip from '../../../CommonComponents/CustomToolTip';
import useUrlParams from '../../hooks/useUrlParams';
import MissionService from '../../../../service/MissionService';
import { get_formated_time } from '../../utils';

interface TLEContainerProps {
    close: (e: any) => void;
    open: boolean;
}

const TLEContainer: FC<TLEContainerProps> = ({ open, close }) => {

    const { launchDetails, getLaunchDetails } = useOrbitParams()
    const { sat_id } = useUrlParams()
    const [tle, setTle] = useState<{
        edeting: boolean;
        line_1: string;
        line_2: string;
    }>({
        edeting: false,
        line_1: '',
        line_2: '',
    })

    const [show, set_show] = useState<string | boolean>(false)
    const [loading, set_loading] = useState(false)
    function parseTLE(tleString: string) {
        const line_1 = tleString.substring(0, 69);
        const line_2 = tleString.substring(69);
        const lines = [line_1, line_2];

        if (lines.length !== 2) {
            toast.error('Invalid TLE format: Expected 2 lines', { toastId: 'tle' });
            return null;
        }

        const parseLine1 = (line: string) => {
            return [
                { label: "Line Number", value: line.substring(0, 1), description: "Always '1'" },
                { label: "Space", value: line.substring(1, 2), description: "Space" },
                { label: "Catalog Number", value: line.substring(2, 7), description: "NORAD catalog number" },
                { label: "Classification", value: line.substring(7, 8), description: "U, C, S" },
                { label: "Space", value: line.substring(8, 9), description: "Space" },
                { label: "International Designator (Launch Year)", value: line.substring(9, 11), description: "Launch year" },
                { label: "International Designator (Launch Number)", value: line.substring(11, 14), description: "Launch number" },
                { label: "International Designator (Piece of Launch)", value: line.substring(14, 17), description: "Piece of the launch" },
                { label: "Space", value: line.substring(17, 18), description: "Space" },
                { label: "Epoch Year", value: line.substring(18, 20), description: "Epoch year" },
                { label: "Epoch Day", value: line.substring(20, 32), description: "Day of the year" },
                { label: "Space", value: line.substring(32, 33), description: "Space" },
                { label: "First Time Derivative of Mean Motion", value: line.substring(33, 43), description: "1st derivative of mean motion" },
                { label: "Space", value: line.substring(43, 44), description: "Space" },
                { label: "Second Time Derivative of Mean Motion", value: line.substring(44, 52), description: "2nd derivative of mean motion" },
                { label: "Space", value: line.substring(52, 53), description: "Space" },
                { label: "BSTAR Drag Term", value: line.substring(53, 61), description: "BSTAR drag term" },
                { label: "Space", value: line.substring(61, 62), description: "Space" },
                { label: "Ephemeris Type", value: line.substring(62, 63), description: "Ephemeris type" },
                { label: "Space", value: line.substring(63, 64), description: "Space" },
                { label: "Element Set Number", value: line.substring(64, 68), description: "Element set number" },
                { label: "Checksum", value: line.substring(68, 69), description: "Checksum" }
            ];
        };

        const parseLine2 = (line: string) => {
            return [
                { label: "Line Number", value: line.substring(0, 1), description: "Always '2'" },
                { label: "Space", value: line.substring(1, 2), description: "Space" },
                { label: "Catalog Number", value: line.substring(2, 7), description: "NORAD catalog number" },
                { label: "Space", value: line.substring(7, 8), description: "Space" },
                { label: "Inclination [degrees]", value: line.substring(8, 16), description: "Inclination" },
                { label: "Space", value: line.substring(16, 17), description: "Space" },
                { label: "Right Ascension of the Ascending Node [degrees]", value: line.substring(17, 25), description: "Right ascension" },
                { label: "Space", value: line.substring(25, 26), description: "Space" },
                { label: "Eccentricity", value: line.substring(26, 33), description: "Eccentricity" },
                { label: "Space", value: line.substring(33, 34), description: "Space" },
                { label: "Argument of Perigee [degrees]", value: line.substring(34, 42), description: "Argument of perigee" },
                { label: "Space", value: line.substring(42, 43), description: "Space" },
                { label: "Mean Anomaly [degrees]", value: line.substring(43, 51), description: "Mean anomaly" },
                { label: "Space", value: line.substring(51, 52), description: "Space" },
                { label: "Mean Motion [revolutions/day]", value: line.substring(52, 63), description: "Mean motion" },
                { label: "Revolution Number at Epoch", value: line.substring(63, 68), description: "Revolution number" },
                { label: "Checksum", value: line.substring(68, 69), description: "Checksum" }
            ];
        };

        try {
            const line1Data = parseLine1(lines[0]);
            const line2Data = parseLine2(lines[1]);
            return [line1Data, line2Data];
        } catch (error) {
            toast.error('Error parsing TLE lines. Check TLE format.', { toastId: 'tle_format' });
            console.error("TLE Parsing Error:", error);
            return null;
        }
    }
    const latest_tle = useMemo(() => launchDetails && launchDetails[0]?.tle || '', [launchDetails])
    const latest_tle_type = useMemo(() => launchDetails && launchDetails[0]?.tleType || '', [launchDetails])
    const orbit_details = useMemo(() => launchDetails && launchDetails[0]?.OrbitParameters, [launchDetails])
    // const latest_tle_time = useMemo(() => moment(new Date(launchDetails[0]?.createdAt).getTime()).startOf('hour').fromNow() || '', [launchDetails])
    const latest_tle_time = useMemo(() => `${launchDetails && launchDetails[0]?.createdAt.split('.')?.[0].split('Z')?.[0]}Z` || '', [launchDetails])

    const check_same_tle = (prev_tle: string, new_tle: { line_1: string, line_2: string }) => {
        const prev_lines = prev_tle;
        const new_lines = `${tle.line_1}${tle.line_2}`;
        if (prev_lines === new_lines) {
            return true
        }
        return false
    }

    const extract_TLE = (inputString: string): {
        line_1: string;
        line_2: string;
    } | null => {
        if (!inputString) {
            return null; // Handle empty input
        }
        const lines = inputString.split(/\r?\n/).filter(line => (line.trim() !== ''));
        if (lines.length < 2) {
            return null; // Not enough lines for a TLE
        }
        const tleLines: string[] = [];
        for (const line of lines) {
            if (line.trim().length < 69) continue
            else if (line.trim().startsWith('1') || line.trim().startsWith('2')) {
                tleLines.push(line.trim());
            }
        }
        if (tleLines.length !== 2) {
            return null; // Didn't find exactly two TLE lines
        }
        return {
            line_1: tleLines[0],
            line_2: tleLines[1],
        }; // Return the TLE as a single string with newlines
    }


    const post_new_tle = () => {
        if (!tle.line_1 || !tle.line_2 || !sat_id || check_same_tle(latest_tle, tle)) return
        set_loading(true)
        const body = {
            "tle": `${tle.line_1}${tle.line_2}`,
            "satId": sat_id,
            "tleType": "Manually"
        }

        MissionService.postOrbitDetails(body).then((res) => {
            if (res) {
                toast.success('TLE updated successfully.', { toastId: 'tle_updated' });
                getLaunchDetails()
            }
        }).catch((err) => {
            toast.error('Error updating TLE.', { toastId: 'tle_updated' });
        }).finally(() => {
            set_loading(false)
        })
    }

    const reset_tle = (prev_tle: string) => {
        const line_1 = prev_tle.substring(0, 69);
        const line_2 = prev_tle.substring(69);
        setTle({ line_1, line_2, edeting: false })
    }

    useEffect(() => {
        if (!open) {
            reset_tle(latest_tle)
            set_show(false)
        }
    }, [open])
    useEffect(() => {
        if (latest_tle && (latest_tle.substring(0, 69) !== tle.line_1 || latest_tle.substring(69) !== tle.line_2)) {
            reset_tle(latest_tle)
        }
    }, [latest_tle])

    if (!latest_tle) return null

    const parsedTLE = parseTLE(latest_tle);

    return (
        <div className={`tle__info__container ${open && 'active'}`}
            onClick={(event) => {
                event.stopPropagation()
                reset_tle(latest_tle)
            }}
        >
            <div className='tle__container__header'>
                <div className='d-flex flex-column'>
                    {!show && <span className='info__title'>TLE</span>}
                    {show === 'history' && <span className='info__title'>TLE History</span>}
                    {show === 'orbit' && <span className='info__title'>Orbit Details</span>}
                    {!show && <span>Last updated: {latest_tle_time}</span>}
                </div>
                <div className='d-flex align-items-center'>
                    <div className='d-flex gap-2 align-items-center me-4'>
                        <button className={`custom__toggle__btn__container ${show === 'orbit' && 'active'}`}
                            onClick={() => {
                                reset_tle(latest_tle)
                                set_show((prev) => prev === 'orbit' ? false : 'orbit')
                            }}
                        />
                        <span className='text-white'>Orbit Details</span>
                    </div>
                    <div className={`tle__type__badge ${latest_tle_type}`}>{latest_tle_type}</div>
                    <CustomToolTip title='TLE History' placement='top'>
                        <button className={`tle__history__button ${show === 'history' && 'active'}`}
                            onClick={() => {
                                reset_tle(latest_tle)
                                set_show((prev) => prev === 'history' ? false : 'history')
                            }}
                        >
                            <TleHistorySvg />
                        </button>
                    </CustomToolTip>
                    <CustomToolTip title='Close' placement='top'>
                        <button className='close_button' onClick={close}>
                            <i className="fe fe-x fs-16 close_icon" />
                        </button>
                    </CustomToolTip>
                </div>
            </div>
            {/* <div className='tle__info_badge'>
                Failed to update in last contact.
            </div> */}
            {!show &&
                <div className='d-flex gap-2 align-items-center w-100 justify-content-between'
                    onClick={(event) => event.stopPropagation()}
                >
                    {!tle.edeting &&
                        <div className='tle__details__section'>
                            {parsedTLE && parsedTLE.map((line, index) => (
                                <div className='d-flex align-items-center' key={index}>
                                    {line.map(({ label, value }, id) => (
                                        <span className='tle__item' key={id}>
                                            <CustomToolTip title={label} placement='top'>
                                                {value}
                                            </CustomToolTip>
                                        </span>
                                    ))}
                                </div>
                            ))}
                        </div>
                    }
                    {tle.edeting &&
                        <div className={`tle__details__section flex-1 ${tle.edeting ? 'active' : ''}`}>
                            <textarea className='tle__input__container' placeholder="Please enter TLE" value={`${tle.line_1}\n${tle.line_2}`}
                                onChange={(e) => {
                                    const tle_input = extract_TLE(e.target.value)
                                    if (tle_input) {
                                        const { line_1, line_2 } = tle_input
                                        setTle({ ...tle, line_1, line_2 })
                                    }
                                }} />
                        </div>
                    }
                    <button className={`save__edit__button ${tle.edeting ? 'active' : ''}`}
                        onClick={(event) => {
                            if (!tle.edeting) {
                                setTle({ ...tle, edeting: true })
                            } else {
                                setTle({ ...tle, edeting: false })
                                post_new_tle()
                            }
                        }}
                    >
                        {loading ? <div className='tle__loader' /> :
                            <Fragment>
                                {tle.edeting ? <SaveTleSvg /> : <EditTleSvg />}
                            </Fragment>
                        }
                    </button>
                </div>
            }
            {show === 'history' &&
                <div className='tle__history__container'>
                    <div className='tle__history__container__header'>
                        <span className='w-150px'>Time</span>
                        <span className='w-100px'>Source</span>
                        <span className='tle__details__section'>Source</span>
                    </div>
                    <div className='tle__history__container__body'>
                        {launchDetails.map((details, id) => {
                            const parsed_TLE = parseTLE(details.tle);
                            return (
                                <div className='d-flex align-items-center justify-content-between' key={id}>
                                    <span className='w-150px'>{details.createdAt.split('.')?.[0].split('Z')?.[0]}Z</span>
                                    <span className='w-100px'>{details.tleType}</span>
                                    <div className='tle__details__section' key={id}>
                                        {parsed_TLE && parsed_TLE.map((line, index) => (
                                            <div className='d-flex align-items-center' key={index}>
                                                {line.map(({ label, value }, id) => (
                                                    <span className='tle__item' key={id}>
                                                        <CustomToolTip title={label} placement='top'>
                                                            {value}
                                                        </CustomToolTip>
                                                    </span>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }

            {show === 'orbit' &&
                <div className="scenario__orbit__info__details__container" style={{ width: '500px' }}>
                    <div className="container__left__section">
                        <div className="info__details">
                            <span>{orbit_details?.['epoch'] && `${orbit_details?.['epoch'] && get_formated_time(orbit_details?.['epoch'])}`}</span>
                            <span>Epoch</span>
                        </div>
                        <div className="info__details">
                            <span>{orbit_details?.['altitude']} km</span>
                            <span>Altitude</span>
                        </div>
                        <div className="info__details">
                            <span>{orbit_details?.['eccentricity'] ? orbit_details?.['eccentricity'] : 0}</span>
                            <span>Eccentricity</span>
                        </div>
                        <div className="info__details">
                            <span>{orbit_details?.['RAAN'] ? orbit_details?.['RAAN'] : 0}°</span>
                            <span>RAAN</span>
                        </div>
                    </div>
                    <div className="container__right__section">

                        <div className="info__details">
                            <span>{orbit_details?.['TA'] ? orbit_details?.['TA'] : 0}°</span>
                            <span>True Anomaly</span>
                        </div>
                        <div className="info__details">
                            <span>{orbit_details?.['inclination'] ? orbit_details?.['inclination'] : 0}°</span>
                            <span>Inclination</span>
                        </div>
                        <div className="info__details">
                            <span>{orbit_details?.['AP'] ? orbit_details?.['AP'] : 0}°</span>
                            <span>Argument of periapsis</span>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default TLEContainer