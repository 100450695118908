// @ts-nocheck
import './Console.scss'
import { CollapseSvg, PinSvg } from '../Svgs';
import { useEffect, useState } from 'react';
import TTCFormSection from './TTCFormSection';
import TaskForm from './TaskForm';
import ConsoleOutputSection from './ConsoleOutputSection';
import FormSubmit from './FormSubmit';
import Modal from '../Modal';
import useModals from '../../../../hooks/useModals';
import useConsole from '../../../../hooks/useConsole';
import useUrlParams from '../../../../hooks/useUrlParams';
import { Modals } from '../../../../providers/ModalProvider';
import { ClassNames } from '@emotion/react';
import RawOutputSection from './RawOutputSection';
import CustomToolTip from '../../../../../CommonComponents/CustomToolTip';
import { Label } from 'reactstrap';
import CustomSelect from '../../../../custom_components/CustomSelect';

const AllBands = [
  { label: "Backdoor", value: "TM-Only" },
  { label: "LeafSpace-S-band", value: "LeafSpace-S-band" },
  { label: "LeafSpace-Uhf", value: "LeafSpace-Uhf" },
]

const Console = () => {
  const { loading, error, commandDetails, getCommandDetails, postData, busComponentOptions, fetchBusCommands, busCommandOptions, allPayloadComponents, uploadAPI } = useConsole();
  const [resizing, setResizing] = useState(false);
  const { dashboard } = useUrlParams();
  const { open_modal, close_modal, pinned_modal, get_modal_details } = useModals()
  const [show_rawbytes, set_show_rawbytes] = useState(false)
  const modal: Modals = get_modal_details('console')


  const [openPanel, setOpenPanel] = useState({
    left: true,
    right: true
  })

  const [communicationType, setCommunicationType] = useState<string>('TT&C')
  const [commandType, setCommandType] = useState<string>('Bus')
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedDropFile, setSelectedDropFile] = useState<File | null>(null);
  const [band, setBand] = useState<null | { label: string, value: string }>(null);


  const openModal = () => {
    setIsModalOpen(true);
    setBand(null);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedFile(null);
    setSelectedDropFile(null);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files?.[0];
    if (file) {
      setSelectedDropFile(file);
      setSelectedFile(null);
      if (file) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const uploadFile = e.target.result;
          // Do something with the file content
          const blob = new Blob([uploadFile], { type: "multipart/form-data" });
          const file_data = new FormData();
          file_data.append("file", blob, file.name);

          setSelectedDropFile({
            file_data: file_data,
            name: file.name,

          })
          setSelectedFile(null)

        };
        reader.readAsText(file);
      } else {
        toast.error("Invalid file format")
      }
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };
  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const uploadFile = e.target.result;
        // Do something with the file content
        const blob = new Blob([uploadFile], { type: "multipart/form-data" });
        const file_data = new FormData();
        file_data.append("file", blob, file.name);

        setSelectedFile({
          file_data: file_data,
          name: file.name,

        })
        setSelectedDropFile(null)

      };
      reader.readAsText(file);
    } else {
      toast.error("Invalid file format")
    }
  };



  const handleImport = () => {
    if (selectedFile || selectedDropFile) {
      uploadAPI(band?.value, selectedFile?.file_data ? selectedFile?.file_data : selectedDropFile?.file_data)?.then((result) => {
        toast.success('File Uploaded', { toastId: '0' })
      }).catch((error) => {
        console.error('File Uploaded failed:', error);
        toast.error(error?.response?.data, { toastId: '0' })
      })
      closeModal();
    }
  };

  const open_panel = (panel: string) => {
    setOpenPanel((prev) => ({
      left: panel === 'left' ? !prev.left : (prev.left || prev.right) ? true : false,
      right: panel === 'right' ? !prev.right : (prev.right || prev.left) ? true : false
    }))
  }


  useEffect(() => {
    const close_open_modal = () => {
      if (!modal.open) return
      else if (modal.open && modal.pinned) return
      else {
        close_modal('console')
        set_show_rawbytes(false)
      }
    }
    window.addEventListener('click', close_open_modal)
    return () => {
      window.removeEventListener('click', close_open_modal)
      sessionStorage.removeItem('taskType')
      sessionStorage.removeItem('selected_task')
      sessionStorage.removeItem('selected_schedule')
    }
  }, [])
  return (
    <Modal
      open={modal.open}
      pinned={modal.current_modal === 'console' && modal.pinned}
      disabled={modal.current_modal === 'console' && modal.pinned || resizing}
      class_name={`${modal.current_modal === 'console' ? 'current__modal' : ''}`}
    >
      <div className={`console__container`}
        onClick={(event: any) => {
          event.stopPropagation()
          open_modal('console')
        }}>
        {isModalOpen && (
          <div className="upload-modal-overlay" onClick={closeModal}>
            <div
              className="upload-modal-container"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="upload-modal-header">
                <h2>Upload Raw Bytes</h2>
                <button className="upload-modal-close" onClick={closeModal}>
                  &times;
                </button>
              </div>
              <h6>Band Type</h6>
              <CustomSelect options={AllBands} value={band} onChange={(data: Option) => { setBand(data) }} />
              <div className="upload-modal-content" style={{ pointerEvents: band?.value ? 'auto' : 'none', opacity: band?.value ? 1 : 0.5 }}>
                {/* Drag & Drop Zone */}
                <div
                  className="upload-drop-zone"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
                  {selectedDropFile ? selectedDropFile.name : "Drag & Drop file here"}
                </div>

                {/* Select from Device */}
                <label className="upload-device-zone">
                  {selectedFile ? selectedFile.name : "Select from Device"}
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileSelect}
                  />
                </label>
              </div>

              <div className="upload-modal-footer">
                <button className="upload-cancel-btn" onClick={closeModal}>
                  Cancel
                </button>
                <button
                  className="upload-import-btn"
                  onClick={handleImport}
                  disabled={!selectedFile && !selectedDropFile}
                >
                  Import
                </button>
              </div>
            </div>
          </div>
        )}
        <div className={`console__container__left__panel 
          ${dashboard === 'summary' && 'disabled'} ${(openPanel.left && dashboard !== 'summary') ? 'active' : ''}`}>
          <div className='console__container__left__panel__container'>
            <div className='left__panel__top__container'>
              <div className='left__panel__header'>
                <span className='header__title'>Console</span>
                <div className='d-flex align-items-center gap-1'>
                  <button className='left__panel__colapse__btn'
                    disabled={dashboard === 'summary'}
                    onClick={(event: any) => {
                      event.stopPropagation()
                      open_panel('left')
                    }}>
                    <CollapseSvg />
                  </button>
                  <button className={`left__panel__pin__btn ${modal.pinned && 'pinned'} ${openPanel.right && 'd-none'}`}
                    onClick={(event: any) => {
                      event.stopPropagation()
                      pinned_modal('console')
                    }}
                  >
                    <PinSvg />
                  </button>
                  <button className={`left__panel__close__btn ${!openPanel.right ? 'd-flex' : 'd-none'}`}
                    onClick={(event: any) => {
                      event.stopPropagation()
                      close_modal('console')
                      set_show_rawbytes(false)
                    }}>
                    <i className="fe fe-x fs-16" />
                  </button>
                </div>
              </div>
              <div className='left__panel__body' onClick={(event: any) => { event.stopPropagation() }}>
                <div className='left__panel__body__communication__btn__container'>
                  {['TT&C', 'TASK'].map(type => (
                    <button disabled={type === 'TASK' && dashboard !== 'operate'} className={`communication_btn ${communicationType === type && 'active'}`} key={type} onClick={() => { setCommunicationType(type) }}>{type}</button>
                  ))}
                </div>
                {communicationType === 'TT&C' &&
                  <TTCFormSection
                    communication_type={communicationType}
                    command_type={commandType}
                    set_command_type={(type) => { setCommandType(type) }}
                    busComponentOptions={busComponentOptions}
                    fetchBusCommands={fetchBusCommands}
                    busCommandOptions={busCommandOptions}
                    allPayloadComponents={allPayloadComponents}
                  />
                }
                {communicationType === 'TASK' &&
                  <TaskForm />
                }
              </div>
            </div>
            <div className='left__panel_bottom__container'>
              <FormSubmit
                postData={postData}
                communicationType={communicationType}
              />
            </div>
          </div>
        </div>
        <div className={`console__container__right__panel ${openPanel.right ? 'active' : ''}`}>
          <div className='console__container__right__panel__container'>
            <div className='right__panel__header'>
              <span className='header__title'>Message</span>
              <div className='d-flex align-items-center gap-1'>
                <div className='d-flex align-items-center gap-1'>
                  <button
                    className="button cursor-pointer"
                    onClick={openModal}
                    style={{ backgroundColor: "transparent" }}
                  >
                    <CustomToolTip title={"Upload File"} placement='top'>
                      <i className="fe fe-upload fs-16 color-text"></i>
                    </CustomToolTip>
                  </button>


                  <button className={`custom__toggle__btn__container ${show_rawbytes && 'active'}`} onClick={() => { set_show_rawbytes(!show_rawbytes) }} />
                  <span>Raw Bytes</span>
                </div>
                <button className='right__panel__colapse__btn'
                  onClick={(event: any) => {
                    event.stopPropagation()
                    open_panel('right')
                  }}>
                  <CollapseSvg />
                </button>
                <button className={`right__panel__pin__btn ${modal.pinned && 'pinned'} ${!openPanel.right && 'd-none'}`}
                  onClick={(event: any) => {
                    event.stopPropagation()
                    pinned_modal('console')
                  }}
                >
                  <PinSvg />
                </button>
                <button className={`right__panel__close__btn ${openPanel.right ? 'd-flex' : 'd-none'}`}
                  onClick={(event: any) => {
                    event.stopPropagation()
                    close_modal('console')
                    set_show_rawbytes(false)
                  }}
                >
                  <i className="fe fe-x fs-16" />
                </button>
              </div>
            </div>
            <div className='right__panel__body'>
              {/* <div className='right__panel__body__header'>
                <button className={`header__badge__btn active`}>IQ Spacecom Xlink-S</button>
                <button className={`header__badge__btn`}>IQ Spacecom Xlink-X</button>
                <button className={`header__badge__btn`}>OpenLST-A UHF</button>
              </div> */}
              {!show_rawbytes &&
                <ConsoleOutputSection
                  loading={loading}
                  error={error}
                  commandDetails={commandDetails}
                  getCommandDetails={getCommandDetails}
                  set_resizing={setResizing}
                />
              }
              {show_rawbytes &&
                <RawOutputSection
                  loading={loading}
                  error={error}
                  commandDetails={commandDetails}
                  getCommandDetails={getCommandDetails}
                  set_resizing={setResizing}
                />
              }

            </div>
          </div>
        </div>
      </div>
    </Modal >
  )
}

export default Console