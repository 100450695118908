//@ts-nocheck
import { useEffect, useState } from 'react';
// import ModalDropdown from '../../../../MissionModeling/Modals/ModalComponents/ModalDropdown';
import './orbitalDetails.css'
import { DatePicker } from 'rsuite';
import ModalInput from '../../../commonComponents/ModalInput';
import ModalDropdown from '../../../commonComponents/ModalDropdown';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const OrbitalDetails = ({ orbitalDetails, setOrbitalDetails, setOrbitType, orbitType, isRepeating, setIsRepeating }) => {
    const creatingConstellation = useSelector(state => state.missionModelling.creatingConstellation);
    const constellationCreateLoader = useSelector(state => state.missionModelling.constellationCreateLoader);
    const [isPreviousSelected, setIsPreviousSelected] = useState('Custom')

    const handleDateChange = (date) => {
        if (date) {
            setOrbitalDetails({
                ...orbitalDetails,
                epoch: date
            })
        }
    };


    const option = [{ label: "Custom", value: "Custom" }, { label: "SSO", value: "SSO" }, { label: "Polar", value: "Polar" }]

    const setInclinationAngle = (orbitType) => {
        switch (orbitType) {
            case 'Polar':
                setOrbitalDetails({
                    ...orbitalDetails,
                    inclination: 90
                })
                break
            case 'SSO':
                setOrbitalDetails({
                    ...orbitalDetails,
                    inclination: 98
                })
                break
            default:
                setOrbitalDetails({
                    ...orbitalDetails,
                    inclination: 37
                })

        }
    }

    const handleSelectedOrbitType = (e) => {
        setOrbitType(e.label)
        setInclinationAngle(e.label)
    }

    const handleSMAChange = (value) => {
        if (Number(value) >= 0 && Number(value) <= 7121 && value.length <= 7) {
            setOrbitalDetails({
                ...orbitalDetails,
                semiMajorAxis: value !== '' ? parseFloat(value) : ''
            })
        }
    }

    const handleEccentricityChange = (value) => {
        if (Number(value) >= 0 && Number(value) <= 1 && value.length <= 7) {
            setOrbitalDetails({
                ...orbitalDetails,
                eccentricity: value !== '' ? parseFloat(value) : ''
            })
        }
    }

    const handleInclinationChange = (value) => {
        if (Number(value) >= 0 && Number(value) <= 180 && value.length <= 7) {
            setOrbitalDetails({
                ...orbitalDetails,
                inclination: value !== '' ? parseFloat(value) : ''
            })
        }
    }

    const handleLANChange = (value) => {
        if (Number(value) >= 0 && Number(value) <= 360 && value.length <= 7) {
            setOrbitalDetails({
                ...orbitalDetails,
                RAAN: value !== '' ? parseFloat(value) : ''
            })
        }
    }

    const handleAOPChange = (value) => {
        if (Number(value) >= 0 && Number(value) <= 360 && value.length <= 7) {
            setOrbitalDetails({
                ...orbitalDetails,
                AOP: value !== '' ? parseFloat(value) : ''
            })
        }
    }

    const handleTAChange = (value) => {
        if (Number(value) >= 0 && Number(value) <= 360 && value.length <= 7) {
            setOrbitalDetails({
                ...orbitalDetails,
                TA: value !== '' ? parseFloat(value) : ''
            })
        }
    }

    const handleOrbitsChange = (value) => {
        setOrbitalDetails({
            ...orbitalDetails,
            orbits: value !== '' ? parseFloat(value) : ''
        })
    }

    return (
        <div className='orbital_body' style={{ pointerEvents: constellationCreateLoader ? 'none' : 'auto' }}>
            <span className='orbital_body_title'> Orbital path type </span>
            <div style={{ width: "100%" }}>
                <ModalDropdown
                    options={option}
                    isMulti={false}
                    handleSelectedOption={handleSelectedOrbitType}
                    previousOption={isPreviousSelected}
                />
            </div>
            <span className='orbital_body_title'> Orbital Details </span>
            <div className='orbital_body_item'>
                <span>Epoch</span>
                <div>
                    <DatePicker
                        format="yyyy-MM-dd HH:mm:ss"
                        onChange={(date) => {
                            if (new Date(date).getFullYear() <= 2037) {
                                handleDateChange(date)
                            } else {
                                toast.error("Please select a date before 2038.", { toastId: 'error' });
                            }
                        }}
                        value={orbitalDetails.epoch}
                        cleanable={false}
                    />
                </div>
            </div>
            <div className='orbital_body_item'>
                <span> Semi-Major Axis (a)</span>
                <div>
                    <ModalInput
                        inputType={'number'}
                        unit={'km'}
                        handleChange={handleSMAChange}
                        value={orbitalDetails.semiMajorAxis} />
                </div>
            </div>

            {<div className='orbital_body_item'>
                <span>Eccentricity (e)</span>
                <div>
                    <ModalInput
                        inputType={'number'}
                        handleChange={handleEccentricityChange}
                        value={orbitalDetails.eccentricity} />
                </div>
            </div>}

            {<div className='orbital_body_item'>
                <span>Inclination (i)</span>
                <div>
                    <ModalInput
                        inputType={'number'}
                        unit={'°'}
                        handleChange={handleInclinationChange}
                        value={orbitalDetails.inclination}
                        disabled={!(orbitType === 'Custom')}
                    />
                </div>
            </div>}

            <div className='orbital_body_item'>
                <span>RAAN (Ω)</span>
                <div>
                    <ModalInput
                        inputType={'number'}
                        unit={'°'}
                        handleChange={handleLANChange}
                        value={orbitalDetails.RAAN} />
                </div>
            </div>

            {<div className='orbital_body_item'>
                <span>Argument of periapsis (ω)</span>
                <div>
                    <ModalInput
                        inputType={'number'}
                        unit={'°'}
                        handleChange={handleAOPChange}
                        value={orbitalDetails.AOP} />
                </div>
            </div>}

            {<div className='orbital_body_item'>
                <span>True Anomaly (ν)</span>
                <div>
                    <ModalInput
                        inputType={'number'}
                        unit={'°'}
                        handleChange={handleTAChange}
                        value={orbitalDetails.TA} />
                </div>
            </div>}
        </div>
    );
};

export default OrbitalDetails;
