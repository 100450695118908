import { useContext } from "react";
import { OrbitContext } from "../providers/OrbitProvider";

const useOrbitParams = () => {
    const context = useContext(OrbitContext)
    if (context === undefined) {
        throw new Error('useContacts must be used within a ViewerProvider');
    }

    const { loading, orbitParams, launchDetails, getLaunchDetails } = context

    return { loading, orbitParams, launchDetails, getLaunchDetails }
};

export default useOrbitParams;