import { useEffect, useRef } from 'react'
import ContactTimeFrame from '../../hud/contact/ContactTimeFrame';
import CustomToolTip from '../../../../../CommonComponents/CustomToolTip';
import { ButtonToolbar, OverlayTrigger } from 'react-bootstrap';
import { GroundTaskSvg } from '../../modals/Svgs';
import { event_status_state } from '../../hud/contact/Svgs';
import { calculate_duration, get_formated_time } from '../../../../utils';
import useTimeline from '../../../../hooks/useTimeline';
import useViewer from '../../../../hooks/useViewer';
import useGroundContact, { ContactStation } from '../../../../hooks/useGroundContact';
import { GroundStation } from '../../../../hooks/useGroundStationData';


const GroundContactSection = () => {
    const containerRef = useRef<HTMLDivElement>(null);
    const { configured_gs, planingContactData, setSelectedContact } = useGroundContact()
    const { current_simulation_time } = useViewer()
    const { window_width, set_window_width, timeframe_details } = useTimeline()
    const { frame_duration, frame_start_time } = timeframe_details

    useEffect(() => {
        const container = containerRef.current;
        if (container instanceof HTMLElement) {
            const width = container.offsetWidth;
            set_window_width(width - 180);
        }
    }, [containerRef.current])

    const get_current_contact = (start_time: number, end_time: number) => {
        const current_time = current_simulation_time / 1000
        if (current_time >= start_time && current_time <= end_time) {
            return 'ongoing__event'
        } else if (current_time < start_time) {
            return 'upcomming__event'
        } else if (current_time > end_time) {
            return 'past__event'
        }
    }

    const get_contact_status = (start_time: number, end_time: number) => {
        const current_time = current_simulation_time / 1000
        if (current_time >= start_time && current_time <= end_time) {
            return 'ongoing'
        } else if (current_time < start_time) {
            return 'upcoming'
        } else if (current_time > end_time) {
            return 'completed'
        } else {
            return 'not_confirm'
        }
    }
    return (
        <div className='ground__container__left__container'>
            <div className='ground__contact__timeframe__section__container' ref={containerRef}>
                <div className="ground__contact__details__title__container">
                    <div className='ground__contact__details color-text'>
                        <span>Ground Station</span>
                    </div>
                </div>
                <ContactTimeFrame />
            </div>
            <div className='ground__contact__details__container'>
                {configured_gs && configured_gs.map((station: GroundStation, stationIndex) => {
                    const station_name = station?.['groundStationName']
                    const station_id = station?.['groundStationId']
                    return (
                        <div className='ground__contact__task__details__container'>
                            <div className="ground__contact__details__title__container">
                                <div className='ground__contact__details border__right'>
                                    <CustomToolTip title={station_name} placement='top-start'>
                                        {station_name}
                                    </CustomToolTip>
                                </div>
                            </div>
                            <div className='task__details__timeframe__container' style={{ width: `${window_width}px` }}>
                                {planingContactData?.['contacts']?.[station_id] && planingContactData?.['contacts']?.[station_id].map((contact: ContactStation, contactIndex: number) => {
                                    const provider = contact?.['groundStationProviderName']
                                    const min_elevation = station?.['groundStationProperties']?.['minElevation'] || 0
                                    const contact_start_time = contact?.['startTime']
                                    const contact_end_time = contact?.['endTime']
                                    const contact_duration = contact?.['duration'] / 60;
                                    const per_sec_pixel = window_width / frame_duration;
                                    const card__width = (contact_duration * 60) * per_sec_pixel;
                                    const left_position = (contact_start_time - frame_start_time) * per_sec_pixel;
                                    const contact_status = get_contact_status(contact_start_time, contact_end_time)
                                    const contact_type = get_current_contact(contact_start_time, contact_end_time)
                                    const station_label = station?.['groundStationProperties']?.['country'] ? `${station_name}, ${station?.['groundStationProperties']?.['country']} ` : station_name
                                    return (
                                        <ButtonToolbar key={`${stationIndex - contactIndex}`}>
                                            <OverlayTrigger delay={0} placement="top" trigger={['hover', 'focus']}
                                                overlay={(
                                                    <div className={`contacts_overview`}>
                                                        <div className='contacts_overview__header'>
                                                            <div className="contact_info w-70">
                                                                <div className="contact_info w-100">
                                                                    <div className="contact_title gap-0">
                                                                        <span className='text-truncate'>{station_label}</span>
                                                                    </div>
                                                                    <span>Ground Station</span>
                                                                </div>
                                                            </div>
                                                            <div className="contact_info w-30 align-items-end">
                                                                <div className="contact_title gap-0">
                                                                    <span>
                                                                        {provider}
                                                                    </span>
                                                                </div>
                                                                <span className="w-auto">Provider</span>
                                                            </div>
                                                        </div>
                                                        <div className='contacts_overview__body'>
                                                            <div className='d-flex w-100 align-items-center justify-content-between'>
                                                                <div className='contact_info w-60'>
                                                                    <span>{contact_start_time && `${get_formated_time(contact_start_time)}`}</span>
                                                                    <span>Start Time</span>
                                                                </div>
                                                                <div className='contact_info w-40'>
                                                                    <span>{calculate_duration(contact_start_time, contact_end_time)}</span>
                                                                    <span>Contact Duration</span>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex w-100 align-items-center justify-content-between'>
                                                                <div className='contact_info w-60'>
                                                                    <span>{min_elevation}<sup>o</sup> </span>
                                                                    <span>Max. Elevation</span>
                                                                </div>
                                                                <div className='contact_info w-40'>
                                                                    <div className='d-flex gap-1 align-items-center'>
                                                                        <div className={`task__status__badge band`}>
                                                                            {contact?.['bandInfo']?.['bandName']}
                                                                        </div>
                                                                    </div>
                                                                    <span>Bands</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}>
                                                <div className={`task__details__card ${contact_type}`}
                                                    style={{
                                                        transform: `translateX(${left_position}px)`,
                                                        width: `${card__width}px`,
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        setSelectedContact({
                                                            selection_type: 'manual',
                                                            contact_type: contact_status === 'ongoing' ? 'Ongoing' : contact_status === 'upcoming' ? 'Upcoming' : 'Previous',
                                                            contact_data: contact,
                                                        })
                                                    }}
                                                >
                                                    <div className="event__indicator">
                                                        <CustomToolTip title={event_status_state[contact_status].state} placement="top">
                                                            {event_status_state[contact_status].svg}
                                                        </CustomToolTip>
                                                    </div>
                                                    <div className="w-100 d-flex h-100 align-items-center justify-content-center">
                                                        {card__width >= 25 &&
                                                            <div className="task__card__container" style={{ width: `${card__width}px` }}>
                                                                <div className="task_card_svg_container">
                                                                    <GroundTaskSvg />
                                                                </div>
                                                                <div className={`task__title ${card__width > 50 && 'show'}`}>{station_name}</div>
                                                            </div>
                                                        }
                                                        {card__width < 25 && <div style={{ width: '20px', height: '30px' }} />}
                                                    </div>
                                                </div>
                                            </OverlayTrigger>
                                        </ButtonToolbar>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default GroundContactSection