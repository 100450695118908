import { useState } from 'react'
import './Sidebar.css'
import { useParams } from 'react-router-dom';
import OperateService from '../../../../service/OperateService';
import { toast } from 'react-toastify';
import CustomToolTip from '../../../CommonComponents/CustomToolTip';
import { CircularProgress } from '@mui/material';
import { CloseArrow, DeleteIcon } from '../Svgs';
import Dialog from '../../../CommonComponents/Dialog';
import { useSelector } from 'react-redux';
import { MissionStore } from '../../../../store/MissionSlice';

interface SidebarProps {
  openModal: (data) => void;
  sidebarLoader: any;
  setSidebarLoader: (data) => void;
  promotedSatellites: any[]
  fetchPromotedSatellites: () => void
  calculate_orbit_details: (data) => void
}

const Sidebar: React.FC<SidebarProps> = ({ openModal, sidebarLoader, setSidebarLoader, promotedSatellites, fetchPromotedSatellites, calculate_orbit_details }) => {
  const params = useParams();
  const { mission_id } = params
  const [openSlider, setOpenSlider] = useState(true)
  const [delete_sat, set_delete_sat] = useState<any>(false)
  const [showContent, setShowContent] = useState(null)
  const userData = useSelector((state: { mission: MissionStore }) => state.mission.userData);

  const delete_promoted_satellite = (promotedId) => {
    setSidebarLoader(true)
    OperateService.deletePromoteSatellite(promotedId)?.then((res) => {
      if (res.data) {
        fetchPromotedSatellites()
        toast.success('Satellite deleted successfully')
      }
    }).catch((err) => {
      console.error(err);
      setSidebarLoader(false)
      toast.error('Something went wrong while deleting satellite')
    })
  }


  return (
    <div className={`${openSlider && "show_side_bar"} side_bar`}>
      <div className='close__side_bar_icon' onClick={() => { setOpenSlider(!openSlider) }} >
        <CloseArrow />
      </div>
      <div className='slider_header'>
        <div className='w-100 d-flex pt-6 px-3'>
          <button className={`slider_header__btn`} onClick={() => { openModal({ type: 'promote' }) }} >
            <span className='fs-20'>Promote Satellite</span>
            <i className='fe fe-plus slider_header__btn__icon fs-18' />
          </button>
        </div>
      </div>
      <div className='slider_caontainer'>
        {sidebarLoader && <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
          <CircularProgress color='inherit' />
        </div>}
        {!sidebarLoader && promotedSatellites.map((item: any, i) => (

          <div key={i} className={`slider_content`}>
            <div className='slider_content_header'>
              <div className='d-flex align-items-center gap-1 name_section text-truncate' onClick={() => {
                calculate_orbit_details(item)
              }}>
                <span className='header_text w-95 text-truncate'>{item?.['name']}</span>
              </div>
              {userData?.["user_roles"]?.[0] !== "project_viewer" &&
                <div className={`btn_action_section gap-2 ${delete_sat === i ? 'active' : ''}`}>
                  <CustomToolTip title="Delete" placement="top">
                    <button className='sat__delete__button'
                      onClick={(e) => {
                        set_delete_sat(item?.['trueTwinId']);
                      }}
                    >
                      <DeleteIcon />
                    </button>
                  </CustomToolTip>
                </div>}
            </div>
          </div>
        ))}
      </div>
      <Dialog
        onClick={(event) => {
          if (event) {
            delete_promoted_satellite(delete_sat)
          }
          set_delete_sat(false)
        }}
        openModel={delete_sat} comment={`Are you sure you want to delete this satellite?`}
      />
    </div>
  )
}

export default Sidebar