

import { useEffect, useState } from "react";
import './Telemetry.scss'
import { CollapseSvg, PinSvg, TelemetrySvg } from "../Svgs";
import Modal from "../Modal";
import useModals from "../../../../hooks/useModals";
import CustomSelect, { Option } from "../../../../custom_components/CustomSelect";
import { Monitor_Btn_List } from "../../monitor/utils";
import useMonitor from "../../../../hooks/useMonitor";
import EPS from "../../../grafana_panels/EPS";
import OBC from "../../../grafana_panels/OBC";
import ADCS from "../../../grafana_panels/ADCS";
import Communication from "../../../grafana_panels/Communication";
import Payload from "../../../grafana_panels/Payload";
import useTrueTwin from "../../../../hooks/useTrueTwin";
import { Modals } from "../../../../providers/ModalProvider";

type Options = {
    label: string;
    value: string;
    disabled?: boolean;
};

const Telemetry = () => {

    const [expand, setExpand] = useState(false);
    const [dropDownOptions, setDropDownOption] = useState<Options[]>([]);
    const { remoteAppConnected, TrueTwin } = useTrueTwin()
    const { open_modal, close_modal, pinned_modal, get_modal_details } = useModals()
    const { panels, selected_panel, change_selected_panel, get_updated_url } = useMonitor()
    const modal: Modals = get_modal_details('telemetry')

    useEffect(() => {
        const close_open_modal = () => {
            if (!modal.open) return
            else if (modal.open && modal.pinned) return
            else {
                close_modal('telemetry')
            }
        }
        window.addEventListener('click', close_open_modal)
        return () => {
            window.removeEventListener('click', close_open_modal)
        }
    }, [])

    const drop_down_options_telemetry = () => {
        if (TrueTwin?.['Category'] !== "FlatSat") {
            const filteredOptions = Monitor_Btn_List.filter((btn) => btn.value !== 'Communication')
            setDropDownOption(filteredOptions);
        } else {
            setDropDownOption(Monitor_Btn_List)
        }
    }

    useEffect(() => {
        drop_down_options_telemetry()
    }, [TrueTwin])

    return (
        <Modal
            open={modal.open}
            pinned={modal.current_modal === 'telemetry' && modal.pinned}
            disabled={modal.current_modal === 'telemetry' && modal.pinned}
            class_name={`${modal.current_modal === 'telemetry' ? 'current__modal' : ''}`}
        >
            <div className={`telemetry__panel__container ${expand && 'expand'} `}
                onClick={(event: any) => {
                    event.stopPropagation()
                    open_modal('telemetry')

                }}>
                <div className="telemetry__panel__header">
                    <div className=" d-flex align-items-center gap-2">
                        <TelemetrySvg />
                        <span className="panel__header__title">Telemetry</span>
                    </div>
                    <div className=" d-flex align-items-center">
                        <button className="panel__colapse__btn" onClick={(event: any) => {
                            event.stopPropagation()
                            setExpand(!expand)
                        }}>
                            <CollapseSvg />
                        </button>
                        <button className={`panel__pin__btn ${modal.pinned ? 'pinned' : ''}`}
                            onClick={(event: any) => {
                                event.stopPropagation()
                                pinned_modal('telemetry')

                            }}>
                            <PinSvg />
                        </button>
                        <button className={`panel__close__btn`} onClick={(event: any) => {
                            event.stopPropagation()
                            close_modal('telemetry')
                        }}>
                            <i className="fe fe-x fs-16" />
                        </button>
                    </div>
                </div>
                <div className="telemetry__panel__body__container" >
                    <div className="telemetry__panel__body__container__header">
                        <CustomSelect
                            className="header__select__container"
                            options={dropDownOptions}
                            disabled_search={true}
                            hide_option={!remoteAppConnected ? 'Payload' : false}
                            disabled_option={!remoteAppConnected ? 'Payload' : false}
                            value={{ label: selected_panel, value: selected_panel }}
                            onChange={(data) => { change_selected_panel(data.label) }}
                        />
                    </div>
                    <div className="telemetry__panel__body__container__body">
                        {selected_panel === 'EPS' && <EPS monitor_view_type={'List'} panels={panels ? panels : []} get_updated_url={get_updated_url} />}
                        {selected_panel === 'OBC' && <OBC monitor_view_type={'List'} panels={panels ? panels : []} get_updated_url={get_updated_url} />}
                        {selected_panel === 'ADCS' && <ADCS monitor_view_type={'List'} panels={panels ? panels : {}} get_updated_url={get_updated_url} />}
                        {selected_panel === 'Communication' && <Communication monitor_view_type={'List'} panels={panels ? panels : {}} get_updated_url={get_updated_url} />}
                        {selected_panel === 'Payload' && <Payload monitor_view_type={'List'} panels={panels ? panels : {}} get_updated_url={get_updated_url} />}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default Telemetry