import { ContactStation } from "../../../../hooks/useGroundContact";
import { get_formated_time } from "../../../../utils";

interface Props {
    open: boolean;
    upcommingContacts: ContactStation[] | null;
    selectedContact: ContactStation | null;
    setSelectedContact: (contact: ContactStation) => void
}

const UpcommingContactsModal: React.FC<Props> = ({ open, upcommingContacts, selectedContact, setSelectedContact }) => {

    return (
        <div className={`overlay__modal ${open && 'active'}`}>
            <div className='modal__container'>
                {/* <div className='modal__header'>
                                <div className='search__icon'>
                                    <SearchSvg />
                                </div>
                                <input />
                            </div> */}
                <div className='modal__body'>
                    {upcommingContacts && upcommingContacts.map((station, index) => {
                        return (
                            <button
                                disabled={!station?.['uplinkConfigured']}
                                className={`contact__details__container ${selectedContact?.['uniqueId'] === station?.['uniqueId'] && 'selected'}`}
                                onClick={() => { setSelectedContact(station) }}
                                key={index}
                            >
                                <div className="icon__container">
                                    {station?.['uplinkConfigured'] && <svg xmlns="http://www.w3.org/2000/svg" width="10" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-move-up"><path d="M8 6L12 2L16 6" /><path d="M12 2V22" /></svg>}
                                    {station?.['downlinkConfigured'] && <svg xmlns="http://www.w3.org/2000/svg" width="10" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-move-down"><path d="M8 18L12 22L16 18" /><path d="M12 2V22" /></svg>}
                                </div>
                                <div className='left__contact__details'>
                                    <span>{`${station?.groundStationName}, ${station?.groundStationProperties?.country}`}</span>
                                    <span>{station?.groundStationProviderName}</span>
                                </div>
                                <div className='right__contact__details'>
                                    <span>{get_formated_time(station?.['startTime'])}</span>
                                    <span>{station?.['bandInfo']?.['bandName']}</span>
                                </div>
                            </button>
                        )
                    })}
                </div>
                {/* 
                    <div className='modal__footer'>
                        <button className='modal__footer__btn'>Any Contact</button>
                    </div> 
                */}
            </div>
        </div>
    )
}

export default UpcommingContactsModal