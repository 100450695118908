import { useEffect, useState } from "react";
import GroundStationService from "../../../service/GroundStationService";

interface FrequencyRange {
    min?: string;
    max?: string;
}

interface Frequencies {
    KAfreq: FrequencyRange;
    Sfreq: FrequencyRange;
    UHFfreq: FrequencyRange;
    Xfreq: FrequencyRange;
}

interface GroundStationProperties {
    altitude: string;
    antenaSize: string;
    country: string;
    freqbands: string[];
    latitude: string;
    location: string;
    longitude: string;
    polarization: string;
    provider: string;
    rxFreq: Frequencies;
    txFreq: Frequencies;
}

export interface GroundStation {
    groundStationId: string;
    groundStationName: string;
    groundStationTime: string;
    missionId: string;
    "owner-org": string; // Use string literal for keys with hyphens
    properties: GroundStationProperties;
    status: string;
}


const useGroundStationData = () => {

    const [all_ground_stations, set_all_ground_stations] = useState<GroundStation[]>([])

    const get_all_ground_stations = () => {
        GroundStationService.getGroundStationsByMissionId()?.then((res) => {
            if (res?.data) {
                set_all_ground_stations(res?.data)
            }
        }).catch((err) => {
            console.error(err);
        });
    }
    useEffect(() => {
        get_all_ground_stations()
    }, [])

    return { all_ground_stations }
}

export default useGroundStationData
