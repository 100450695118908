import { useContext } from "react"
import { OrbitContext } from "../providers/OrbitProvider"


const useOrbit = () => {
    const context = useContext(OrbitContext)
    if (context === undefined) {
        throw new Error('useContacts must be used within a ViewerProvider');
    }
    const { orbitData } = context

    return { orbitData }
}

export default useOrbit