import React, { createContext, ReactNode, useEffect, useState } from 'react'
import CommandCenterService from '../../../service/CommandCenterService'
import MissionService from '../../../service/MissionService'
import useUrlParams from '../hooks/useUrlParams'
import useError from '../hooks/useError'

export type Orbit = {
    documentMetadata: any,
    groundStation: any,
    areaOfInterest: any,
    groundTarget: any,
    satToGsConnection: any,
    satToGTConnection: any,
    satellite: any
}

export type OrbitParams = {
    "epoch": number,
    "altitude": number,
    "eccentricity": number,
    "inclination": number,
    "TA": number,
    "RAAN": number,
    "AP": number,
    "semiMajorAxis": number
}

export type LaunchDetails = {
    "tle": string,
    "createdAt": string,
    "satId": string,
    "tleType": string,
    "OrbitParameters": OrbitParams
}

type OrbitContextType = {
    orbitData: Orbit | null,
    orbitParams: OrbitParams,
    launchDetails: LaunchDetails[],
    getLaunchDetails: () => void,
    loading: boolean
}

export const OrbitContext = createContext<OrbitContextType | undefined>(undefined);

interface OrbitProviderProps {
    children: ReactNode;
}

const OrbitProvider: React.FC<OrbitProviderProps> = ({ children }) => {

    const { sat_id, scenario_id, dashboard, truetwin_id } = useUrlParams()
    const [orbitParams, setOrbitParams] = useState<OrbitParams>({} as OrbitParams);
    const [launchDetails, setLaunchDetails] = useState<LaunchDetails[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const [orbitData, setOrbitData] = useState<Orbit | null>(null)
    const { setError } = useError()

    const getOrbitParams = () => {
        CommandCenterService.getScenarioOrbitParams(scenario_id).then(res => {
            if (res.data) {
                setOrbitParams(res.data)
            }
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
    }

    const getLaunchDetails = () => {
        MissionService.getAllOrbitDetails(sat_id).then(res => {
            if (res.data) {
                const launchDetails = res.data.sort((a: LaunchDetails, b: LaunchDetails) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                setLaunchDetails(launchDetails)
            }
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
    }

    const getOrbitDetails = () => {
        const propagation = dashboard === 'summary' ? 'summary' : 'dashboard'
        CommandCenterService.getOribtData(scenario_id, truetwin_id, propagation).then(res => {
            if (res?.data) {
                setOrbitData(res?.data)
            }
        }).catch(err => {
            const error_msg = err?.message
            const error_code = error_msg === 'Network Error' ? error_msg : '500'
            // setError(error_code)
        })
    }

    useEffect(() => {
        if (dashboard !== 'operate' && scenario_id && truetwin_id) {
            getOrbitDetails()
        }
    }, [dashboard, scenario_id, truetwin_id])

    useEffect(() => {
        if (scenario_id && dashboard !== 'operate') {
            getOrbitParams()
        } else if (sat_id && dashboard === 'operate') {
            getLaunchDetails()
        }
    }, [scenario_id, sat_id])

    return (
        <OrbitContext.Provider value={{ orbitData, orbitParams, launchDetails, loading, getLaunchDetails }}>
            {children}
        </OrbitContext.Provider>
    )
}

export default OrbitProvider