
import { FC, Fragment, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Loader from "../../../../../Layouts/Loader/Loader";
import MissionService from "../../../../../service/MissionService";
import Select from "react-select";
import CatalogueService from "../../../../../service/CatalogueService";
import { toast } from "react-toastify";
import CustomOverLay from "../../../../CommonComponents/CustomOverLay";
import GroundStationService from "../../../../../service/GroundStationService";
import { CircularProgress } from "@mui/material";
import Dialog from "../../../../CommonComponents/Dialog";
import CustomSelect from "../../../../CommonComponents/CustomSelect";

interface MQTTConfigurationModalProps {
    topic_details?: any;
    provider_details: any;
    provider_ground_stations: any[];
    close: () => void;
    set_show_details: (data) => void;
}
const MQTTConfigurationModal: FC<MQTTConfigurationModalProps> = ({ topic_details, provider_details, provider_ground_stations, close, set_show_details }) => {
    const params = useParams();
    const { mission_id } = params

    const [selectedTab, setSelectedTab] = useState('Satellite')
    const [MQTTTopicDetails, setMQTTTopicDetails] = useState<any>({})
    const [templateDetails, setTemplateDetails] = useState<any>({})
    const [allSatellites, setAllSatellites] = useState<any>([])
    const [selectedSatellite, setSelectedSatellite] = useState<any>(null)
    const [loading, setLoading] = useState(true)
    const [temploading, setTempLoading] = useState(false)
    const [showError, setShowError] = useState<any>(false)
    const [isShowModal, setIsShowModal] = useState<any>(false)


    const get_topic_details = () => {
        let topic_details: any = {}
        const bands = {
            'Sfreq': 'S-band',
            'Xfreq': 'X-band',
            'UHFfreq': 'UHF',
        }
        provider_ground_stations.map(station => {
            Object.keys(station?.['properties']?.["rxFreq"]).map(freq => {
                if (bands[freq] && station?.['properties']?.["rxFreq"][freq] && (Object.keys(station?.['properties']?.["rxFreq"][freq])?.length > 0)) {
                    topic_details = {
                        ...topic_details,
                        [bands[freq]]: {
                            name: '',
                            bandId: topic_details?.[bands[freq]] ? {
                                ...topic_details?.[bands[freq]].bandId,
                                downlink: '',
                            } : {
                                downlink: '',
                            },
                            mqttTopic: topic_details?.[bands[freq]] ? {
                                ...topic_details?.[bands[freq]].mqttTopic,
                                downlink: '',
                            } : {
                                downlink: '',
                            }
                        }
                    }
                }
            })
            Object.keys(station?.['properties']?.["txFreq"]).map(freq => {
                if (bands[freq] && station?.['properties']?.["txFreq"][freq] && (Object.keys(station?.['properties']?.["txFreq"][freq])?.length > 0)) {
                    topic_details = {
                        ...topic_details,
                        [bands[freq]]: {
                            name: '',
                            bandId: topic_details?.[bands[freq]] ? {
                                ...topic_details?.[bands[freq]].bandId,
                                uplink: '',
                            } : {
                                uplink: '',
                            },
                            mqttTopic: topic_details?.[bands[freq]] ? {
                                ...topic_details?.[bands[freq]].mqttTopic,
                                uplink: '',
                            } : {
                                uplink: '',
                            }
                        }
                    }
                }
            })
        })
        return topic_details
    }

    const get_updated_topic_details = (new_topic_details, topic_details) => {
        let updated_topic_details = {
            gspSatId: topic_details?.['topic']?.['gspSatId'],
            satelliteId: topic_details?.['topic']?.['satelliteId'],
            satelliteName: topic_details?.['topic']?.['satelliteName'],
        }
        topic_details?.['topic']?.['topic']?.map((topic) => {
            if (topic?.['bandName'] in new_topic_details) {
                new_topic_details = {
                    ...new_topic_details,
                    [topic?.['bandName']]: {
                        name: new_topic_details?.[topic?.['bandName']]?.['name'],
                        bandId: {
                            ...new_topic_details[topic?.['bandName']].bandId,
                            ...topic?.['bandId']
                        },
                        mqttTopic: {
                            ...new_topic_details[topic?.['bandName']].mqttTopic,
                            ...topic?.['mqttTopic']
                        },
                        rmId: topic?.['rmId']
                    }
                }
            }
        })
        updated_topic_details = {
            ...updated_topic_details,
            ...new_topic_details,
        }
        setSelectedSatellite({
            name: topic_details?.['topic']?.['satelliteName'],
            value: topic_details?.['topic']?.['satelliteName'],
        })
        setMQTTTopicDetails(updated_topic_details)
        setSelectedTab('Transceiver')
    }

    const get_template_details = (sat_data) => {
        const template_id = sat_data?.["versions"]?.[0]?.["assembly"]?.["Template"]

        let new_topic_details = get_topic_details()
        setTempLoading(true)

        CatalogueService.getTemplate(template_id)?.then((res) => {
            if (res?.data) {
                setTemplateDetails(res?.data);
                let bus_com_bands: any = {}
                Object.keys(res?.data?.['assembly']?.[0]?.['communication']?.['comm-devices'])?.map((key) => {
                    if (!bus_com_bands[key]) {
                        bus_com_bands = {
                            ...bus_com_bands,
                            [key]: res?.data?.['assembly']?.[0]?.['communication']?.['comm-devices']?.[key]?.['atmos-data']?.['name'],
                        }
                    }
                })

                Object.keys(new_topic_details).map(band => {
                    if (!bus_com_bands[band]) {
                        delete new_topic_details[band]
                    } else {
                        new_topic_details = {
                            ...new_topic_details,
                            [band]: {
                                ...new_topic_details[band],
                                name: bus_com_bands[band],
                            }
                        }
                    }
                })

                if (topic_details && Object.keys(topic_details)?.length > 0) {
                    get_updated_topic_details(new_topic_details, topic_details)
                } else {
                    setMQTTTopicDetails({
                        satelliteId: sat_data?.['uniqueId'],
                        satelliteName: sat_data?.['name'],
                        gspSatId: MQTTTopicDetails?.['gspSatId'] ? MQTTTopicDetails?.['gspSatId'] : '',
                        ...new_topic_details
                    })
                }

                setSelectedTab('Transceiver')
                setTempLoading(false)
            }
        }).catch((err) => {
            console.error(err);
            setTempLoading(false)
        })
    }

    const get_satellites = () => {
        MissionService.get_satellites_by_mission_id(mission_id)?.then((res) => {
            if (res?.data) {
                let provider_MQTT_sat_ids = provider_details?.MQTTTopicList && provider_details?.MQTTTopicList?.map(topic => topic?.satelliteId)
                let filter_satellite = res?.data?.filter(sat => !provider_MQTT_sat_ids?.includes(sat?.['uniqueId']))
                let satellites: any[] = []
                filter_satellite?.filter(sat => {
                    if (sat?.['draftMode'] === 'No') {
                        satellites.push({
                            label: sat?.name,
                            value: sat
                        })
                    }
                })
                setAllSatellites(satellites)
            }
            setLoading(false)
        }).catch((err) => {
            console.error(err);
            setLoading(false)
        })
    }
    const get_satellite = (satelliteId) => {
        MissionService.get_satellite_by_sat_id(satelliteId)?.then((res) => {
            if (res?.data) {
                get_template_details(res?.data)
            }
            setLoading(false)
        }).catch((err) => {
            console.error(err);
            setLoading(false)
        })
    }

    const check_validation = () => {
        // for (const key in MQTTTopicDetails) {
        //     if (
        //         typeof MQTTTopicDetails?.[key] === 'object' && MQTTTopicDetails?.[key]?.['bandId']?.['uplink'] !== '' && MQTTTopicDetails?.[key]?.['mqttTopic']?.['uplink'] !== '' &&
        //         (MQTTTopicDetails?.[key]?.['rmId'] === '' || !MQTTTopicDetails?.[key]?.['rmId'])
        //     ) {
        //         toast.error(`Uplink Rm ID is required for ${key}.`, { toastId: 'o' });
        //         return false; // Validation failed
        //     }
        // }
        return true; // Validation passed
    };


    const update_provider_details = () => {
        let validation = check_validation();
        if (validation) {
            let all_topic_list = provider_details?.['MQTTTopicList'] ? [...provider_details?.['MQTTTopicList'], MQTTTopicDetails] : [MQTTTopicDetails]
            let filter_topic_list = all_topic_list?.filter(topic => topic?.['satelliteId'] !== MQTTTopicDetails?.['satelliteId'])
            let topic_details: any = {
                gspSatId: MQTTTopicDetails?.['gspSatId'],
                satelliteId: MQTTTopicDetails?.['satelliteId'],
                satelliteName: MQTTTopicDetails?.['satelliteName'],
                topic: []
            }
            let provider_mqttConfiguredBands: string[] = []
            Object.keys(MQTTTopicDetails)?.forEach((key) => {
                if (typeof MQTTTopicDetails?.[key] === 'object') {
                    if (!provider_mqttConfiguredBands?.includes(key)) {
                        provider_mqttConfiguredBands.push(key)
                    }
                    topic_details = {
                        ...topic_details,
                        topic: [
                            ...topic_details?.['topic'],
                            {
                                bandName: key,
                                name: MQTTTopicDetails?.[key]?.['name'],
                                bandId: MQTTTopicDetails?.[key]?.['bandId'],
                                rmId: MQTTTopicDetails?.[key]?.['rmId'] ? MQTTTopicDetails?.[key]?.['rmId'] : 0,
                                mqttTopic: MQTTTopicDetails?.[key]?.['mqttTopic']
                            }

                        ]
                    }
                }
            });
            let topic_list = [...filter_topic_list, topic_details]
            let updated_provider = {
                ...provider_details,
                mqttConfiguredBands: provider_mqttConfiguredBands,
                'MQTTTopicList': topic_list,
                nextConfigStep: (!provider_details?.['MQTTEndpoint'] || provider_details?.['MQTTEndpoint'] === '') ? 'Configure Provider' : (!provider_details?.['groundStations'] || provider_details?.['groundStations']?.length === 0) ? 'Add Ground Station' : '',
                status: ((!provider_details?.['MQTTEndpoint'] || provider_details?.['MQTTEndpoint'] === '') || (!provider_details?.['groundStations'] || provider_details?.['groundStations']?.length === 0)) ? 'Not Configured' : 'Configured',
            }
            GroundStationService.updateGsProvider(provider_details?.['uniqueId'], updated_provider).then((res: any) => {
                if (res.data) {
                    toast.success('Provider updated successfully.')
                    set_show_details(selectedSatellite?.['uniqueId'])
                    close()
                }
            }).catch((err) => {
                console.error(err);
                toast.error('Failed to update provider.')
            })
        }
    }

    const onKeyDown = (e) => {
        if (['e', 'E', '+', '-', '.'].includes(e.key) || e.keyCode === 13) {
            e.preventDefault();
        }
    }


    useEffect(() => {
        if (mission_id && provider_details) {
            get_satellites()
        }
    }, [mission_id, provider_details])



    useEffect(() => {
        if (topic_details && Object.keys(topic_details)?.length > 0) {
            get_satellite(topic_details?.['topic']?.['satelliteId'])
            setSelectedSatellite({
                name: topic_details?.['topic']?.['satelliteName'],
                value: topic_details?.['topic']?.['satelliteName'],
            })
            setSelectedTab('Transceiver')
        }
    }, [topic_details])

    return (
        <Fragment>
            {loading && <Loader />}
            <div className='popup-model z-2'>
                <div className={`model-lg gap-3 p-0 mt-7 transition-width`}>
                    <div className='w-100 d-flex justify-content-between align-items-center px-5 pt-5'>
                        <span className='form-heade-text fs-18' >MQTT Topic Configuration</span>
                        <i onClick={() => { setIsShowModal(true); }} className="fe fe-x-circle me-1 fs-25 cursor-pointer text-white"></i>
                    </div>

                    <div className="modal_devider" />
                    <div className="w-100 d-flex flex-column gap-3">
                        <Form validated={showError} className="w-100 d-flex gap-3 py-3 px-5 ">
                            <div className={`w-50 d-flex flex-column gap-2 ${topic_details && topic_details?.['mode'] === 'Update' ? 'opacity-50' : ''}`}>
                                <span>Select Satellite *</span>
                                <CustomSelect
                                    isDisabled={topic_details && topic_details?.['mode'] === 'Update'}
                                    options={allSatellites}
                                    placeholder="Select Satellite"
                                    value={selectedSatellite ? { label: selectedSatellite?.['name'], value: selectedSatellite } : null}

                                    onChange={(e: any) => {
                                        setSelectedTab('Satellite')
                                        get_template_details(e.value)
                                        setSelectedSatellite(e.value)
                                    }}
                                />
                            </div>
                            <div className="d-flex w-50 flex-column gap-2">
                                <span>
                                    Provider Satellite ID *
                                    <CustomOverLay
                                        placement="top"
                                        title="Satellite ID Provided by Ground Station Provider."
                                    >
                                        <sup>
                                            <i className="fe fe-info ms-1 fs-14 text-info"></i>
                                        </sup>
                                    </CustomOverLay>
                                </span>
                                <Form.Control className="flex-1" placeholder="Enter Provider Satellite ID"
                                    required
                                    value={MQTTTopicDetails?.['gspSatId']}
                                    onChange={(e) => {
                                        setShowError(e.target.value?.length > 0 ? false : true)
                                        setMQTTTopicDetails({
                                            ...MQTTTopicDetails,
                                            gspSatId: e.target.value.trimStart()
                                        })
                                    }}
                                />
                                <span className='fs-12 text-danger'>{showError ? 'Please Enter Provider Satellite ID ' : ''}</span>
                            </div>
                        </Form>
                        {!selectedSatellite &&
                            <div className="px-5">
                                <div className="gsp_badge_info px-5 py-3 gap-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 2.5C5.85938 2.5 2.5 5.85938 2.5 10C2.5 14.1406 5.85938 17.5 10 17.5C14.1406 17.5 17.5 14.1406 17.5 10C17.5 5.85938 14.1406 2.5 10 2.5ZM9.76562 14.375C9.61111 14.375 9.46006 14.3292 9.33159 14.2433C9.20311 14.1575 9.10298 14.0355 9.04384 13.8927C8.98471 13.75 8.96924 13.5929 8.99939 13.4413C9.02953 13.2898 9.10394 13.1506 9.2132 13.0413C9.32246 12.9321 9.46166 12.8577 9.61321 12.8275C9.76476 12.7974 9.92184 12.8128 10.0646 12.872C10.2074 12.9311 10.3294 13.0312 10.4152 13.1597C10.5011 13.2882 10.5469 13.4392 10.5469 13.5938C10.5469 13.801 10.4646 13.9997 10.3181 14.1462C10.1715 14.2927 9.97283 14.375 9.76562 14.375ZM11.0719 10.3906C10.4387 10.8156 10.3516 11.2051 10.3516 11.5625C10.3516 11.7075 10.2939 11.8466 10.1914 11.9492C10.0888 12.0518 9.94973 12.1094 9.80469 12.1094C9.65965 12.1094 9.52055 12.0518 9.41799 11.9492C9.31543 11.8466 9.25781 11.7075 9.25781 11.5625C9.25781 10.7066 9.65156 10.0262 10.4617 9.48203C11.2148 8.97656 11.6406 8.65625 11.6406 7.95195C11.6406 7.47305 11.3672 7.10938 10.8012 6.84023C10.668 6.77695 10.3715 6.71523 10.0066 6.71953C9.54883 6.72539 9.19336 6.83477 8.91953 7.05508C8.40312 7.4707 8.35938 7.92305 8.35938 7.92969C8.35591 8.0015 8.33834 8.07194 8.30766 8.13696C8.27697 8.20199 8.23379 8.26033 8.18055 8.30866C8.12732 8.357 8.06509 8.39437 7.99742 8.41866C7.92974 8.44294 7.85795 8.45366 7.78613 8.4502C7.71432 8.44673 7.64388 8.42916 7.57886 8.39848C7.51383 8.36779 7.45549 8.3246 7.40716 8.27137C7.35882 8.21814 7.32145 8.15592 7.29716 8.08824C7.27288 8.02057 7.26216 7.94877 7.26562 7.87695C7.26992 7.78203 7.33594 6.92695 8.2332 6.20508C8.69844 5.83086 9.29023 5.63633 9.99102 5.62773C10.4871 5.62188 10.9531 5.70586 11.2691 5.85508C12.2148 6.30234 12.7344 7.04805 12.7344 7.95195C12.7344 9.27344 11.8512 9.8668 11.0719 10.3906Z" fill="#EEEEEE" />
                                    </svg>
                                    <span>Select a Satellite to continue with the MQTT configuration process.</span>
                                </div>
                            </div>
                        }
                        {(Object.keys(MQTTTopicDetails)?.length < 4 && selectedSatellite) &&
                            <div className="px-5">
                                <div className="gsp_badge_info px-5 py-3 gap-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 2.5C5.85938 2.5 2.5 5.85938 2.5 10C2.5 14.1406 5.85938 17.5 10 17.5C14.1406 17.5 17.5 14.1406 17.5 10C17.5 5.85938 14.1406 2.5 10 2.5ZM9.76562 14.375C9.61111 14.375 9.46006 14.3292 9.33159 14.2433C9.20311 14.1575 9.10298 14.0355 9.04384 13.8927C8.98471 13.75 8.96924 13.5929 8.99939 13.4413C9.02953 13.2898 9.10394 13.1506 9.2132 13.0413C9.32246 12.9321 9.46166 12.8577 9.61321 12.8275C9.76476 12.7974 9.92184 12.8128 10.0646 12.872C10.2074 12.9311 10.3294 13.0312 10.4152 13.1597C10.5011 13.2882 10.5469 13.4392 10.5469 13.5938C10.5469 13.801 10.4646 13.9997 10.3181 14.1462C10.1715 14.2927 9.97283 14.375 9.76562 14.375ZM11.0719 10.3906C10.4387 10.8156 10.3516 11.2051 10.3516 11.5625C10.3516 11.7075 10.2939 11.8466 10.1914 11.9492C10.0888 12.0518 9.94973 12.1094 9.80469 12.1094C9.65965 12.1094 9.52055 12.0518 9.41799 11.9492C9.31543 11.8466 9.25781 11.7075 9.25781 11.5625C9.25781 10.7066 9.65156 10.0262 10.4617 9.48203C11.2148 8.97656 11.6406 8.65625 11.6406 7.95195C11.6406 7.47305 11.3672 7.10938 10.8012 6.84023C10.668 6.77695 10.3715 6.71523 10.0066 6.71953C9.54883 6.72539 9.19336 6.83477 8.91953 7.05508C8.40312 7.4707 8.35938 7.92305 8.35938 7.92969C8.35591 8.0015 8.33834 8.07194 8.30766 8.13696C8.27697 8.20199 8.23379 8.26033 8.18055 8.30866C8.12732 8.357 8.06509 8.39437 7.99742 8.41866C7.92974 8.44294 7.85795 8.45366 7.78613 8.4502C7.71432 8.44673 7.64388 8.42916 7.57886 8.39848C7.51383 8.36779 7.45549 8.3246 7.40716 8.27137C7.35882 8.21814 7.32145 8.15592 7.29716 8.08824C7.27288 8.02057 7.26216 7.94877 7.26562 7.87695C7.26992 7.78203 7.33594 6.92695 8.2332 6.20508C8.69844 5.83086 9.29023 5.63633 9.99102 5.62773C10.4871 5.62188 10.9531 5.70586 11.2691 5.85508C12.2148 6.30234 12.7344 7.04805 12.7344 7.95195C12.7344 9.27344 11.8512 9.8668 11.0719 10.3906Z" fill="#EEEEEE" />
                                    </svg>
                                    <span>The satellite selected does not have transceiver with bands operated by this GS provider.</span>
                                </div>
                            </div>
                        }
                        {(allSatellites?.length === 0 && !topic_details) &&
                            <div className="px-5">
                                <div className="gsp_badge_info gap-3 ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 2.5C5.85938 2.5 2.5 5.85938 2.5 10C2.5 14.1406 5.85938 17.5 10 17.5C14.1406 17.5 17.5 14.1406 17.5 10C17.5 5.85938 14.1406 2.5 10 2.5ZM9.76562 14.375C9.61111 14.375 9.46006 14.3292 9.33159 14.2433C9.20311 14.1575 9.10298 14.0355 9.04384 13.8927C8.98471 13.75 8.96924 13.5929 8.99939 13.4413C9.02953 13.2898 9.10394 13.1506 9.2132 13.0413C9.32246 12.9321 9.46166 12.8577 9.61321 12.8275C9.76476 12.7974 9.92184 12.8128 10.0646 12.872C10.2074 12.9311 10.3294 13.0312 10.4152 13.1597C10.5011 13.2882 10.5469 13.4392 10.5469 13.5938C10.5469 13.801 10.4646 13.9997 10.3181 14.1462C10.1715 14.2927 9.97283 14.375 9.76562 14.375ZM11.0719 10.3906C10.4387 10.8156 10.3516 11.2051 10.3516 11.5625C10.3516 11.7075 10.2939 11.8466 10.1914 11.9492C10.0888 12.0518 9.94973 12.1094 9.80469 12.1094C9.65965 12.1094 9.52055 12.0518 9.41799 11.9492C9.31543 11.8466 9.25781 11.7075 9.25781 11.5625C9.25781 10.7066 9.65156 10.0262 10.4617 9.48203C11.2148 8.97656 11.6406 8.65625 11.6406 7.95195C11.6406 7.47305 11.3672 7.10938 10.8012 6.84023C10.668 6.77695 10.3715 6.71523 10.0066 6.71953C9.54883 6.72539 9.19336 6.83477 8.91953 7.05508C8.40312 7.4707 8.35938 7.92305 8.35938 7.92969C8.35591 8.0015 8.33834 8.07194 8.30766 8.13696C8.27697 8.20199 8.23379 8.26033 8.18055 8.30866C8.12732 8.357 8.06509 8.39437 7.99742 8.41866C7.92974 8.44294 7.85795 8.45366 7.78613 8.4502C7.71432 8.44673 7.64388 8.42916 7.57886 8.39848C7.51383 8.36779 7.45549 8.3246 7.40716 8.27137C7.35882 8.21814 7.32145 8.15592 7.29716 8.08824C7.27288 8.02057 7.26216 7.94877 7.26562 7.87695C7.26992 7.78203 7.33594 6.92695 8.2332 6.20508C8.69844 5.83086 9.29023 5.63633 9.99102 5.62773C10.4871 5.62188 10.9531 5.70586 11.2691 5.85508C12.2148 6.30234 12.7344 7.04805 12.7344 7.95195C12.7344 9.27344 11.8512 9.8668 11.0719 10.3906Z" fill="#EEEEEE" />
                                    </svg>
                                    <span> There are no Satellites to Configure MQTT Topic, Add Satellite to Configure MQTT Topic.</span>
                                </div>
                            </div>
                        }
                        {Object.keys(MQTTTopicDetails)?.length >= 4 && <div className="modal_devider" />}

                        <div className={`d-flex transition-height overflow-auto px-5 py-3 ${Object.keys(MQTTTopicDetails)?.length >= 5 ? 'h-365' : 'h-300'} `}>
                            {Object.keys(MQTTTopicDetails)?.length >= 4 &&
                                <div className="d-flex w-100 flex-column gap-3">
                                    <div className="w-100 d-flex align-items-center rounded-4 justify-content-between border py-3 px-5">
                                        <span className="fs-18 color-table-header-text w-20">TRANSCEIVER</span>
                                        <span className="fs-18 color-table-header-text w-30 d-flex align-items-center justify-content-center">BAND ID</span>
                                        <span className="fs-18 color-table-header-text w-40 d-flex align-items-center justify-content-center">MQTT TOPIC</span>
                                        <span className="fs-18 color-table-header-text w-10 d-flex align-items-center justify-content-center">RM ID</span>
                                    </div>
                                    {!temploading &&
                                        <Fragment>
                                            {MQTTTopicDetails?.['S-band'] &&
                                                <div className={`transceiver_item_container`}>
                                                    <span className="fs-14 color-text w-20 ps-5 text-truncate">{MQTTTopicDetails?.['S-band']?.['name']}</span>
                                                    <div className="d-flex gap-2 w-75 align-items-center flex-column">
                                                        <div className="d-flex gap-2 w-100 align-items-center justify-content-between">
                                                            {'uplink' in MQTTTopicDetails?.['S-band']?.['bandId'] &&
                                                                <Form.Control className="w-30" placeholder="uplink"
                                                                    required
                                                                    value={MQTTTopicDetails?.['S-band']?.['bandId']?.['uplink']}
                                                                    onChange={(e) => {
                                                                        if (e.target.value?.length <= 15) {
                                                                            setMQTTTopicDetails({
                                                                                ...MQTTTopicDetails,
                                                                                'S-band': {
                                                                                    ...MQTTTopicDetails['S-band'],
                                                                                    bandId: {
                                                                                        ...MQTTTopicDetails['S-band']['bandId'],
                                                                                        uplink: e.target.value.trimStart()
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    }}
                                                                />
                                                            }

                                                            {'uplink' in MQTTTopicDetails?.['S-band']?.['mqttTopic'] &&
                                                                <Form.Control className="w-55" placeholder="uplink"
                                                                    required
                                                                    value={MQTTTopicDetails?.['S-band']?.['mqttTopic']?.['uplink']}
                                                                    onChange={(e) => {
                                                                        if (e.target.value?.length <= 100) {
                                                                            setMQTTTopicDetails({
                                                                                ...MQTTTopicDetails,
                                                                                'S-band': {
                                                                                    ...MQTTTopicDetails['S-band'],
                                                                                    mqttTopic: {
                                                                                        ...MQTTTopicDetails['S-band']['mqttTopic'],
                                                                                        uplink: e.target.value.trimStart()
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            {'uplink' in MQTTTopicDetails?.['S-band']?.['mqttTopic'] &&
                                                                <Form.Control className="w-15" placeholder="rm Id"
                                                                    required
                                                                    disabled={!MQTTTopicDetails?.['S-band']?.['bandId']?.['uplink'] || !MQTTTopicDetails?.['S-band']?.['mqttTopic']?.['uplink']}
                                                                    onKeyDown={onKeyDown}
                                                                    value={MQTTTopicDetails?.['S-band']?.['rmId']}
                                                                    onChange={(e) => {
                                                                        const value = e.target.value;
                                                                        if (value === '' || (!isNaN(+value) && +value >= 0 && +value <= 4)) {
                                                                            setMQTTTopicDetails({
                                                                                ...MQTTTopicDetails,
                                                                                'S-band': {
                                                                                    ...MQTTTopicDetails['S-band'],
                                                                                    rmId: value === '' ? '' : Number(value),
                                                                                },
                                                                            });
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                        </div>
                                                        <div className="d-flex gap-2 w-100 align-items-center justify-content-between">
                                                            {'downlink' in MQTTTopicDetails?.['S-band']?.['bandId'] &&
                                                                <Form.Control className="w-30" placeholder="downlink"
                                                                    required
                                                                    value={MQTTTopicDetails?.['S-band']?.['bandId']?.['downlink']}
                                                                    onChange={(e) => {
                                                                        if (e.target.value?.length <= 15) {
                                                                            setMQTTTopicDetails({
                                                                                ...MQTTTopicDetails,
                                                                                'S-band': {
                                                                                    ...MQTTTopicDetails['S-band'],
                                                                                    bandId: {
                                                                                        ...MQTTTopicDetails['S-band']['bandId'],
                                                                                        downlink: e.target.value.trimStart()
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    }}
                                                                />
                                                            }

                                                            {'downlink' in MQTTTopicDetails?.['S-band']?.['mqttTopic'] &&
                                                                <Form.Control className="w-70" placeholder="downlink"
                                                                    required
                                                                    value={MQTTTopicDetails?.['S-band']?.['mqttTopic']?.['downlink']}
                                                                    onChange={(e) => {
                                                                        if (e.target.value?.length <= 100) {
                                                                            setMQTTTopicDetails({
                                                                                ...MQTTTopicDetails,
                                                                                'S-band': {
                                                                                    ...MQTTTopicDetails['S-band'],
                                                                                    mqttTopic: {
                                                                                        ...MQTTTopicDetails['S-band']['mqttTopic'],
                                                                                        downlink: e.target.value.trimStart()
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {MQTTTopicDetails?.['X-band'] &&
                                                <div className={`transceiver_item_container`}>
                                                    <span className="fs-14 color-text w-20 ps-5 text-truncate">{MQTTTopicDetails?.['X-band']?.['name']}</span>
                                                    <div className="d-flex gap-2 w-75 align-items-center flex-column">
                                                        <div className="d-flex gap-2 w-100 align-items-center justify-content-between">
                                                            {'uplink' in MQTTTopicDetails?.['X-band']?.['bandId'] &&
                                                                <Form.Control className="w-30" placeholder="uplink"
                                                                    required
                                                                    value={MQTTTopicDetails?.['X-band']?.['bandId']?.['uplink']}
                                                                    onChange={(e) => {
                                                                        if (e.target.value?.length <= 15) {
                                                                            setMQTTTopicDetails({
                                                                                ...MQTTTopicDetails,
                                                                                'X-band': {
                                                                                    ...MQTTTopicDetails['X-band'],
                                                                                    bandId: {
                                                                                        ...MQTTTopicDetails['X-band']['bandId'],
                                                                                        uplink: e.target.value.trimStart()
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            {'uplink' in MQTTTopicDetails?.['X-band']?.['mqttTopic'] &&
                                                                <Form.Control className="w-55" placeholder="uplink"
                                                                    required
                                                                    value={MQTTTopicDetails?.['X-band']?.['mqttTopic']?.['uplink']}
                                                                    onChange={(e) => {
                                                                        if (e.target.value?.length <= 100) {
                                                                            setMQTTTopicDetails({
                                                                                ...MQTTTopicDetails,
                                                                                'X-band': {
                                                                                    ...MQTTTopicDetails['X-band'],
                                                                                    mqttTopic: {
                                                                                        ...MQTTTopicDetails['X-band']['mqttTopic'],
                                                                                        uplink: e.target.value.trimStart()
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            {'uplink' in MQTTTopicDetails?.['X-band']?.['mqttTopic'] &&
                                                                <Form.Control className="w-15" placeholder="rm Id"
                                                                    required
                                                                    onKeyDown={onKeyDown}
                                                                    type="number"
                                                                    disabled={!MQTTTopicDetails?.['X-band']?.['bandId']?.['uplink'] || !MQTTTopicDetails?.['X-band']?.['mqttTopic']?.['uplink']}
                                                                    value={MQTTTopicDetails?.['X-band']?.['rmId']}
                                                                    onChange={(e) => {
                                                                        const value = e.target.value;
                                                                        // Allow only empty string or numbers within the range 0 to 4
                                                                        if (value === '' || (!isNaN(+value) && +value >= 0 && +value <= 4)) {
                                                                            setMQTTTopicDetails({
                                                                                ...MQTTTopicDetails,
                                                                                'X-band': {
                                                                                    ...MQTTTopicDetails['X-band'],
                                                                                    rmId: value === '' ? '' : Number(value),
                                                                                },
                                                                            });
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                        </div>
                                                        <div className="d-flex gap-2 w-100 align-items-center justify-content-between">
                                                            {'downlink' in MQTTTopicDetails?.['X-band']?.['bandId'] &&
                                                                <Form.Control className="w-30" placeholder="downlink"
                                                                    required
                                                                    value={MQTTTopicDetails?.['X-band']?.['bandId']?.['downlink']}
                                                                    onChange={(e) => {
                                                                        if (e.target.value?.length <= 15) {
                                                                            setMQTTTopicDetails({
                                                                                ...MQTTTopicDetails,
                                                                                'X-band': {
                                                                                    ...MQTTTopicDetails['X-band'],
                                                                                    bandId: {
                                                                                        ...MQTTTopicDetails['X-band']['bandId'],
                                                                                        downlink: e.target.value.trimStart()
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            {'downlink' in MQTTTopicDetails?.['X-band']?.['mqttTopic'] &&
                                                                <Form.Control className="w-70" placeholder="downlink"
                                                                    required
                                                                    value={MQTTTopicDetails?.['X-band']?.['mqttTopic']?.['downlink']}
                                                                    onChange={(e) => {
                                                                        if (e.target.value?.length <= 100) {
                                                                            setMQTTTopicDetails({
                                                                                ...MQTTTopicDetails,
                                                                                'X-band': {
                                                                                    ...MQTTTopicDetails['X-band'],
                                                                                    mqttTopic: {
                                                                                        ...MQTTTopicDetails['X-band']['mqttTopic'],
                                                                                        downlink: e.target.value.trimStart()
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {MQTTTopicDetails?.['UHF'] &&
                                                <div className={`transceiver_item_container`}>
                                                    <span className="fs-14 color-text w-20 ps-5 text-truncate">{MQTTTopicDetails?.['UHF']?.['name']}</span>
                                                    <div className="d-flex gap-2 w-75 align-items-center flex-column">
                                                        <div className="d-flex gap-2 w-100 align-items-center justify-content-between">
                                                            {'uplink' in MQTTTopicDetails?.['UHF']?.['bandId'] &&
                                                                <Form.Control className="w-30" placeholder="uplink"
                                                                    required
                                                                    value={MQTTTopicDetails?.['UHF']?.['bandId']?.['uplink']}
                                                                    onChange={(e) => {
                                                                        if (e.target.value?.length <= 15) {
                                                                            setMQTTTopicDetails({
                                                                                ...MQTTTopicDetails,
                                                                                'UHF': {
                                                                                    ...MQTTTopicDetails['UHF'],
                                                                                    bandId: {
                                                                                        ...MQTTTopicDetails['UHF']['bandId'],
                                                                                        uplink: e.target.value.trimStart()
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            {'uplink' in MQTTTopicDetails?.['UHF']?.['mqttTopic'] &&
                                                                <Form.Control className="w-55" placeholder="uplink"
                                                                    required
                                                                    value={MQTTTopicDetails?.['UHF']?.['mqttTopic']?.['uplink']}
                                                                    onChange={(e) => {
                                                                        if (e.target.value?.length <= 100) {
                                                                            setMQTTTopicDetails({
                                                                                ...MQTTTopicDetails,
                                                                                'UHF': {
                                                                                    ...MQTTTopicDetails['UHF'],
                                                                                    mqttTopic: {
                                                                                        ...MQTTTopicDetails['UHF']['mqttTopic'],
                                                                                        uplink: e.target.value.trimStart()
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            {'uplink' in MQTTTopicDetails?.['UHF']?.['mqttTopic'] &&
                                                                <Form.Control className="w-15" placeholder="rm Id"
                                                                    required
                                                                    onKeyDown={onKeyDown}
                                                                    type="number"
                                                                    disabled={!MQTTTopicDetails?.['UHF']?.['bandId']?.['uplink'] || !MQTTTopicDetails?.['UHF']?.['mqttTopic']?.['uplink']}
                                                                    value={MQTTTopicDetails?.['UHF']?.['rmId']}
                                                                    onChange={(e) => {
                                                                        const value = e.target.value;
                                                                        // Allow only empty string or numbers within the range 0 to 4
                                                                        if (value === '' || (!isNaN(+value) && +value >= 0 && +value <= 4)) {
                                                                            setMQTTTopicDetails({
                                                                                ...MQTTTopicDetails,
                                                                                'UHF': {
                                                                                    ...MQTTTopicDetails['UHF'],
                                                                                    rmId: value === '' ? '' : Number(value),
                                                                                },
                                                                            });
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                        </div>
                                                        <div className="d-flex gap-2 w-100 align-items-center justify-content-between">

                                                            {'downlink' in MQTTTopicDetails?.['UHF']?.['bandId'] &&
                                                                <Form.Control className="w-30" placeholder="downlink"
                                                                    required
                                                                    value={MQTTTopicDetails?.['UHF']?.['bandId']?.['downlink']}
                                                                    onChange={(e) => {
                                                                        if (e.target.value?.length <= 15) {
                                                                            setMQTTTopicDetails({
                                                                                ...MQTTTopicDetails,
                                                                                'UHF': {
                                                                                    ...MQTTTopicDetails['UHF'],
                                                                                    bandId: {
                                                                                        ...MQTTTopicDetails['UHF']['bandId'],
                                                                                        downlink: e.target.value.trimStart()
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            {'downlink' in MQTTTopicDetails?.['UHF']?.['mqttTopic'] &&
                                                                <Form.Control className="w-70" placeholder="downlink"
                                                                    required
                                                                    value={MQTTTopicDetails?.['UHF']?.['mqttTopic']?.['downlink']}
                                                                    onChange={(e) => {
                                                                        if (e.target.value?.length <= 100) {
                                                                            setMQTTTopicDetails({
                                                                                ...MQTTTopicDetails,
                                                                                'UHF': {
                                                                                    ...MQTTTopicDetails['UHF'],
                                                                                    mqttTopic: {
                                                                                        ...MQTTTopicDetails['UHF']['mqttTopic'],
                                                                                        downlink: e.target.value.trimStart()
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </Fragment>
                                    }
                                    {temploading &&
                                        <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                                            <CircularProgress color='inherit' />
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <div className="modal_devider" />
                    <div className="d-flex justify-content-between align-items-center w-100 px-5 pb-5">
                        <button className="btn btn-outline-primary" onClick={close}>Cancel</button>
                        <button className="btn btn-primary" disabled={((Object.keys(MQTTTopicDetails)?.length < 4 && selectedSatellite)) || !selectedSatellite}
                            onClick={() => {
                                if (MQTTTopicDetails?.['gspSatId'] === '') {
                                    toast.error('Please Enter Provider Satellite ID ')
                                    setShowError(true)
                                } else {
                                    update_provider_details()
                                }
                            }}
                        >{topic_details?.['mode'] === 'Update' ? 'Update' : 'Save'}</button>
                    </div>
                </div>
            </div>
            <Dialog
                onClick={(event) => {
                    if (event) {
                        close()
                    }
                    setIsShowModal(false);
                }}
                openModel={isShowModal}
                comment="Are you sure you want to discard the changes?"
            />
        </Fragment>
    )
}

export default MQTTConfigurationModal
