import { DateRangePicker } from 'rsuite';
import moment from 'moment-timezone';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import useMonitor from '../hooks/useMonitor';

const utcTimezone = 'Etc/UTC';


const TimePicker = () => {
    const { telemetry_time, set_telemetry_time } = useMonitor()

    const handleChange = (value: DateRange | null, event: React.SyntheticEvent<Element, Event>) => {
        try {
            if (value) {
                const utcDates = value.map(date => date ? moment.utc(date).toDate() : null) as [Date | null, Date | null];
                set_telemetry_time(utcDates);
            } else {
                set_telemetry_time(null)
            }
        } catch (error) {
            console.log('Time Picker Error:', error);
        }
    };

    const formatForPicker = (date: Date | null) => {
        return date ? moment(date).tz(utcTimezone).format('yyyy-MM-dd HH:mm:ss') : '';
    }
    return (
        <DateRangePicker
            value={telemetry_time && telemetry_time[0] && telemetry_time[1] ? telemetry_time as [Date, Date] : null}
            onChange={handleChange}
            format='yyyy-MM-dd HH:mm:ss'
            placement='bottomEnd'
            size='md'
            // showOneCalendar
            renderValue={(value) => `${formatForPicker(value[0])} - ${formatForPicker(value[1])}`} // Use the custom format function
            placeholder="Select Date Range (UTC)"
            ranges={[
                {
                    label: 'Today',
                    value: [moment().tz(utcTimezone).startOf('day').toDate(), moment().tz(utcTimezone).endOf('day').toDate()],
                },
                {
                    label: 'Yesterday',
                    value: [
                        moment().tz(utcTimezone).subtract(1, 'days').startOf('day').toDate(),
                        moment().tz(utcTimezone).subtract(1, 'days').endOf('day').toDate(),
                    ],
                },
                {
                    label: 'Last 7 days',
                    value: [moment().tz(utcTimezone).subtract(6, 'days').startOf('day').toDate(), moment().tz(utcTimezone).endOf('day').toDate()],
                }
            ]}
        />
    )
}

export default TimePicker