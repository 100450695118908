import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FixedSizeList as List } from 'react-window';
import { v4 as uuidv4 } from "uuid";

import CommandCenterService from '../../../../../../service/CommandCenterService';
import useUrlParams from '../../../../hooks/useUrlParams';
import CustomToolTip from '../../../../../CommonComponents/CustomToolTip';
import useScenario from '../../../../hooks/useScenario';
import './Console.scss'
import { getEnvVar } from '../../../../../../envUtils';
import { get_formated_time } from '../../../../utils';


const fetchEntries = async (sat_id, truetwin_id, scenario_id, cursor) => {
    let response_data = {
        data: [],
        cursor: '',
    };
    try {
        const response = await CommandCenterService.getAllRawCommandDetails(
            sat_id,
            truetwin_id,
            scenario_id,
            cursor,
            50
        );
        // Extract cursorId from the first entry in the response
        const cursorId = response.data?.[0]?.['cursorId'] || '';
        const sorted_data = response.data ? response.data : []
        response_data = {
            data: sorted_data,
            cursor: cursorId,
        };
    } catch (error) {
        console.log(error);
    }

    return response_data;
};

function RawOuputList({ height, show_selected_output_details, selectedRow }) {
    const { sat_id, truetwin_id, scenario_id } = useUrlParams(); // Get parameters from the custom hook
    const { scenario } = useScenario()

    const [entries, setEntries] = useState([]);
    const [loading, setLoading] = useState(false);
    const listRef = useRef(null);
    const cursorRef = useRef('')
    const scrllRef = useRef(false)

    const loadMoreEntries = async () => {
        if (!sat_id || !truetwin_id || !scenario_id || !scrllRef.current) return; // Ensure parameters are available
        setLoading(true);
        const { data, cursor: newCursor } = await fetchEntries(sat_id, truetwin_id, scenario_id, cursorRef.current, 50);
        if (data.length && newCursor) {
            setEntries((prev) => [...prev, ...data].sort((a, b) => b.CommandDetails.groundTimestamp - a.CommandDetails.groundTimestamp));
            cursorRef.current = newCursor // Update the cursor with the new one from the API
        }
        setLoading(false);
    };

    const loadLatestEntries = async () => {
        if (!sat_id || !truetwin_id || !scenario_id || scrllRef.current) return; // Ensure parameters are available
        
        setLoading(true);
        const { data, cursor: newCursor } = await fetchEntries(sat_id, truetwin_id, scenario_id, '', 50); // Reset the cursor to fetch the latest
        setEntries(data);  // Replace the current entries with the latest ones
        cursorRef.current = newCursor // Update the cursor with the new one from the API
        if (listRef.current) {
            listRef.current.scrollTo(0, 0); // Scroll to top
        }
        setLoading(false);
    };

    useEffect(() => {
        loadLatestEntries(); // Load initial entries
    }, [sat_id, truetwin_id, scenario_id]);


    const handleScroll = useCallback(({ scrollOffset, scrollDirection }) => {
        if (scrollDirection === 'forward' && !loading) {
            const listElement = listRef.current;
            if (listElement) {
                scrllRef.current = true
                const pixelBuffer = Math.ceil(window.devicePixelRatio); // Adjust buffer dynamically
                const bottomReached =
                    scrollOffset + listElement.props.height + pixelBuffer >=
                    listElement.props.itemSize * entries.length;

                // Ensure cursor is valid before loading more entries
                if (bottomReached && cursorRef.current) {
                    loadMoreEntries();
                }
            }
        } else if(scrollDirection === 'backward' && scrollOffset === 0){
            scrllRef.current = false
            loadLatestEntries();
        }
    }, []);


    //web socket start//
    const create_socket = (sessionId) => {
        try {
            return new WebSocket(`${getEnvVar('REACT_APP_WEBSOCKET_BACKEND_BASE_URL')}groundstation/websocketstatus?truetwinId=${truetwin_id}&sessionId=${sessionId}`);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        const token = sessionStorage.getItem("authentication");
        if (scenario?.['trueTwinId'] && token) {
            const sessionId = uuidv4();
            let ws = create_socket(sessionId)
            ws.onopen = () => {
                ws.send(JSON.stringify({ type: 'authorization', token: `${token}` }));
                console.log('WebSocket Connected');
            };
            ws.onmessage = (event) => {
                loadLatestEntries()
            };

            ws.onclose = () => {
                console.log('WebSocket Disconnected');
            };

            ws.onerror = (error) => {
                console.error('WebSocket Error:', error);
                let ws = create_socket(sessionId)
            };
            return () => {
                ws.close();
            };
        }
    }, [scenario]);

    const base64ToHex = (str) => {
        const raw = atob(str);
        let result = '';
        for (let i = 0; i < raw.length; i++) {
            const hex = raw.charCodeAt(i).toString(16);
            result += (hex.length === 2 ? hex : '0' + hex);
            result += ' ';
        }
        return result.toUpperCase();
    };

    const Row = useCallback(({ index, style }) => {
        const entry = entries[index];
        return (
            <div key={entry?.id} style={style}>
                <div className={`table__body__row`}>
                    <button
                        onClick={() => {
                            show_selected_output_details(entry);
                        }}
                        className={`table__body__row__item gap-3 ${(selectedRow && selectedRow?.['CommandUid'] === entry?.['CommandUid']) ? 'selected' : ''}`}
                        style={{
                            borderLeft: (selectedRow && selectedRow?.['CommandUid'] === entry?.['CommandUid']) ? "2px solid #CCF54E" : undefined,
                        }}
                    >
                        <div className='d-flex align-items-center justify-content-start' style={{ width: '160px' }}>
                            <span className='truncate__text'>
                                <CustomToolTip title={get_formated_time(entry?.CommandDetails?.['groundTimestamp'] / 1000)} placement='top'>
                                    {entry?.CommandDetails?.['groundTimestamp'] ? get_formated_time(entry?.CommandDetails?.['groundTimestamp'] / 1000) : '--'}
                                </CustomToolTip>
                            </span>
                        </div>
                        <div className='d-flex align-items-center justify-content-start' style={{width: '850px'}}>
                            <span className='truncate__text'>
                                {base64ToHex(entry?.CommandDetails?.['completeFrame'] || '')}
                            </span>
                        </div>
                    </button>
                </div>
            </div>
        );
    },[entries, selectedRow]);

    return (
        <div>
            <List
                ref={listRef}
                height={height}
                itemCount={entries?.length}
                itemSize={35} // Adjusted height for posts
                width={'100%'}
                onScroll={handleScroll}
            >
                {Row}
            </List>
        </div>
    );
}

export default RawOuputList;
