// @ts-nocheck
import React, { createElement, useEffect } from 'react'
import { FilterSvg } from '../Svgs'
import { Resizable } from 'react-resizable';
import './Console.scss'
import RawOuputList from './RawOuputList';

interface IProps {
    loading: boolean;
    error: string | null;
    commandDetails: any | null;
    getCommandDetails: (commandId: string) => void;
    set_resizing: (resizing: boolean) => void;
}


const RawOutputSection: React.FC<IProps> = ({ error, commandDetails, getCommandDetails, set_resizing }) => {

    const [outputModalSize, setOutputModalSize] = React.useState<{
        width: number,
        height: number,
        resizing: boolean | string,
    }>({
        width: 500,
        height: 462,
        resizing: false,
    })

    const [selectedRow, setSelectedRow] = React.useState<any>(null)
    const [hexString, setHexString] = React.useState<any>('')

    const show_selected_output_details = (selected_command: any) => {
        const already_selected = selectedRow?.CommandUid === selected_command?.CommandUid;
        setSelectedRow(already_selected ? '' : selected_command)
        if (!already_selected) {
            updateHexAndPayload(selected_command);
        } else {
            setHexString('');
        }
    }

    const updateHexAndPayload = (selected_command: any) => {
        const base64String = selected_command.CommandDetails.completeFrame || "";
        setHexString(base64ToHex(base64String));
    };


    const onResizeHeight = (event, { node, size, handle }) => {
        event.stopPropagation()
        if (size.height >= 100 && size.height <= 500) {
            setOutputModalSize((prev) => ({
                ...prev,
                height: size.height,
                resizing: 'height',
            }))
        }
    };
    const onResizeWidth = (event, { node, size, handle }) => {
        event.stopPropagation()
        if (size.width >= 200 && size.width <= 1000) {
            setOutputModalSize((prev) => ({
                ...prev,
                width: size.width,
                resizing: 'width',
            }))
        }
    };

    const onResizeStop = (event: MouseEvent) => {
        event.stopPropagation()
        set_resizing(false)
        setOutputModalSize((prev) => ({
            ...prev,
            resizing: false,
        }))
    };
    const onResizeStart = (event: MouseEvent) => {
        event.stopPropagation()
        set_resizing(true)
    };
    const base64ToHex = (str: string): string => {
        const raw = atob(str);
        let result = '';
        for (let i = 0; i < raw.length; i++) {
            const hex = raw.charCodeAt(i).toString(16);
            result += (hex.length === 2 ? hex : '0' + hex);
            result += ' ';
        }
        return result.toUpperCase();
    };


    useEffect(() => {
        const clearFormFields = () => {
            setSelectedRow('');
            setHexString('');
        };

        window.addEventListener('clearFormFields', clearFormFields);
        return () => {
            window.removeEventListener('clearFormFields', clearFormFields);
        };
    }, []);


    return (
        <div className='right__panel__body__content__container'>
            <div className='content__container__table__header gap-3'>
                <div className='d-flex align-items-center justify-content-between' style={{ width: '160px' }}>
                    <span>Received Time</span>
                    <button className='table__header__btn' disabled>
                        <FilterSvg />
                    </button>
                </div>
                <div className='d-flex align-items-center justify-content-between' style={{ width: '850px' }}>
                    <span>Data</span>
                </div>
            </div>
            <Resizable
                className="position-relative"
                height={outputModalSize.height}
                onResizeStop={onResizeStop}
                onResizeStart={onResizeStart}
                onResize={onResizeHeight}
                handle={(handleAxis, ref) => <div ref={ref} className={`container__table__body__resize__handler ${outputModalSize.resizing === 'height' && 'active'} handle-${handleAxis}`} />}>
                <div style={{ height: outputModalSize.height + 20 }}>
                    <div className='content__container__table__body'>
                        <RawOuputList
                            height={outputModalSize.height}
                            show_selected_output_details={show_selected_output_details}
                            selectedRow={selectedRow}
                        />
                    </div>
                </div>

            </Resizable>
            <div className={`output__modal`} >
                <div className='output__modal__right__sectio w-100'>
                    <div
                        onMouseDown={(e) => {
                            e.stopPropagation();  // Prevents window dragging while selecting text
                        }}
                        onSelectStart={(e) => {
                            e.stopPropagation();  // Ensures text can be selected without triggering drag
                        }}
                    >
                        {hexString ? hexString : 'Not available'}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RawOutputSection;
