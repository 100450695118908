
// @ts-nocheck
import React, { useEffect } from 'react'
import { FilterSvg } from '../Svgs'
import { Resizable } from 'react-resizable';
import './Console.scss'
import ConsoleOuputList from './ConsoleOuputList'

interface IProps {
    loading: boolean;
    error: string | null;
    commandDetails: any | null;
    getCommandDetails: (commandId: string) => void;
    set_resizing: (resizing: boolean) => void;
}


const ConsoleOutputSection: React.FC<IProps> = ({ error, commandDetails, getCommandDetails, set_resizing }) => {

    const [outputModalSize, setOutputModalSize] = React.useState<{
        width: number,
        height: number,
        resizing: boolean | string,
    }>({
        width: 500,
        height: 462,
        resizing: false,
    })

    const [selectedRow, setSelectedRow] = React.useState<any>({
        CommandUid: '',
        selectedRow: '',
        commandIndexs: [],
    })
    const [commandType, setCommandType] = React.useState<any>('')
    const [hexString, setHexString] = React.useState<any>('')
    const [foundPayload, setFoundPayload] = React.useState<any>('')

    const show_selected_output_details = (selected_command: any, entries: any) => {
        setCommandType(selected_command?.type)
        const positions: number[] = []
        entries?.map((output) => {
            if (output.CommandUid === selected_command.CommandUid) {
                positions.push(entries?.indexOf(output))
            }
        })

        const selected_row = entries.indexOf(selected_command)
        const already_selected = selected_row === selectedRow.selectedRow ? true : false
        setSelectedRow({
            CommandUid: already_selected ? '' : selected_command?.CommandUid,
            selectedRow: already_selected ? '' : selected_row,
            commandIndexs: already_selected ? [] : positions,
        })
        if (!already_selected) {
            updateHexAndPayload(selected_command);
        } else {
            setHexString('');
            setFoundPayload('');
            setCommandType('');
        }
    }

    const updateHexAndPayload = (selected_command: any) => {
        let base64String = '';
        let payload = '';
        if (commandDetails && commandDetails.data) {
            commandDetails.data.forEach(command => {
                if (command.type === commandType) {
                    base64String = command.CommandDetails.completeFrame || "";
                    payload = command.CommandDetails || '';
                }
            });
        }
        delete payload.payloadBytes
        delete payload.completeFrame

        setHexString(base64ToHex(base64String));
        setFoundPayload(payload);
    };

    useEffect(() => {
        if (commandType && commandDetails) {
            updateHexAndPayload(selectedRow.selectedRow);
        }
    }, [commandType, commandDetails]);


    const onResizeHeight = (event, { node, size, handle }) => {
        event.stopPropagation()
        if (size.height >= 100 && size.height <= 500) {
            setOutputModalSize((prev) => ({
                ...prev,
                height: size.height,
                resizing: 'height',
            }))
        }
    };
    const onResizeWidth = (event, { node, size, handle }) => {
        event.stopPropagation()
        if (size.width >= 200 && size.width <= 1000) {
            setOutputModalSize((prev) => ({
                ...prev,
                width: size.width,
                resizing: 'width',
            }))
        }
    };

    const onResizeStop = (event: MouseEvent) => {
        event.stopPropagation()
        set_resizing(false)
        setOutputModalSize((prev) => ({
            ...prev,
            resizing: false,
        }))
    };
    const onResizeStart = (event: MouseEvent) => {
        event.stopPropagation()
        set_resizing(true)
    };
    const base64ToHex = (str: string): string => {
        const raw = atob(str);
        let result = '';
        for (let i = 0; i < raw.length; i++) {
            const hex = raw.charCodeAt(i).toString(16);
            result += (hex.length === 2 ? hex : '0' + hex);
            result += ' ';
        }
        return result.toUpperCase();
    };


    useEffect(() => {
        const clearFormFields = () => {
            setSelectedRow({
                CommandUid: '',
                selectedRow: '',
                commandIndexs: [],
            });
            setHexString('');
            setFoundPayload('');
            setCommandType('');
        };

        window.addEventListener('clearFormFields', clearFormFields);
        return () => {
            window.removeEventListener('clearFormFields', clearFormFields);
        };
    }, []);


    return (
        <div className='right__panel__body__content__container'>
            <div className='content__container__table__header gap-2'>
                {/* <div className='d-flex align-items-center justify-content-between' style={{ width: '120px' }}>
                    <span>Link</span>
                    <button className='table__header__btn' disabled>
                        <FilterSvg />
                    </button>
                </div> */}
                <div className='d-flex align-items-center justify-content-between' style={{ width: '90px' }}>
                    <span>Command</span>
                    <button className='table__header__btn' disabled>
                        <FilterSvg />
                    </button>
                </div>
                <div className='d-flex align-items-center justify-content-between' style={{ width: '160px' }}>
                    <span>Time</span>
                    <button className='table__header__btn' disabled>
                        <FilterSvg />
                    </button>
                </div>
                <div className='d-flex align-items-center justify-content-between' style={{ width: '160px' }}>
                    <span>Receiver Time</span>
                    <button className='table__header__btn' disabled>
                        <FilterSvg />
                    </button>
                </div>
                <div className='d-flex align-items-center justify-content-between' style={{ width: '80px' }}>
                    <span>Source</span>
                    <button className='table__header__btn' disabled>
                        <FilterSvg />
                    </button>
                </div>
                <div className='d-flex align-items-center justify-content-between' style={{ width: '100px' }}>
                    <span>Destination</span>
                    <button className='table__header__btn' disabled>
                        <FilterSvg />
                    </button>
                </div>
                <div className='d-flex align-items-center justify-content-start' style={{ width: '100px' }}>
                    <span>Bytes Length</span>
                </div>
                <div className='d-flex align-items-center justify-content-between' style={{ width: '100px' }}>
                    <span>TC/TM ID</span>
                    <button className='table__header__btn' disabled>
                        <FilterSvg />
                    </button>
                </div>
                <div className='d-flex align-items-center justify-content-start' style={{ width: '140px' }}>
                    <span>Info</span>
                </div>
            </div>
            <Resizable
                className="position-relative"
                height={outputModalSize.height}
                onResizeStop={onResizeStop}
                onResizeStart={onResizeStart}
                onResize={onResizeHeight}
                handle={(handleAxis, ref) => <div ref={ref} className={`container__table__body__resize__handler ${outputModalSize.resizing === 'height' && 'active'} handle-${handleAxis}`} />}>
                <div style={{ height: outputModalSize.height + 20 }}>
                    <div className='content__container__table__body'>
                        <ConsoleOuputList
                            height={outputModalSize.height}
                            getCommandDetails={getCommandDetails}
                            show_selected_output_details={show_selected_output_details}
                            selectedRow={selectedRow}
                        />
                    </div>
                </div>

            </Resizable>
            <div className={`output__modal`} >
                <Resizable
                    className="position-relative"
                    width={outputModalSize.width}
                    onResizeStop={onResizeStop}
                    onResizeStart={onResizeStart}
                    onResize={onResizeWidth}
                    handle={(handleAxis, ref) => <div ref={ref} className={`output__modal__left__resize__handler ${outputModalSize.resizing === 'width' && 'active'} handle-${handleAxis}`} />}>
                    <div className='output__modal__left__section' style={{ width: outputModalSize.width }}>
                        <span>
                            Packet Details:
                        </span>
                        {foundPayload ? (
                            <pre style={{
                                backgroundColor: 'transparent',
                                color: '#abaaaa',
                                textShadow: 'none',
                                padding: 0,
                                overflow: 'auto',
                            }}
                                onMouseDown={(e) => {
                                    e.stopPropagation();  // Prevents window dragging while selecting text
                                }}
                                onSelectStart={(e) => {
                                    e.stopPropagation();  // Text can be selected without triggering drag
                                }}
                            >
                                {JSON.stringify(foundPayload, null, 2)}
                            </pre>

                        ) :
                            'Not available'
                        }
                    </div>
                </Resizable>

                <div className='output__modal__right__section'>
                    <div
                        onMouseDown={(e) => {
                            e.stopPropagation();  // Prevents window dragging while selecting text
                        }}
                        onSelectStart={(e) => {
                            e.stopPropagation();  // Ensures text can be selected without triggering drag
                        }}
                    >
                        {hexString ? hexString : 'Not available'}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConsoleOutputSection;
