import React, { FC, Fragment, useEffect, useState } from 'react'
import MQTTConfigurationModal from './Modals/MQTTConfigurationModal';
import { ButtonGroup, Dropdown } from "react-bootstrap"
import GroundStationService from '../../../../service/GroundStationService';
import { toast } from 'react-toastify';
import Dialog from '../../../CommonComponents/Dialog';

interface MQTTConfigurationProps {
  provider_details: any;
  all_ground_stations: any[];
  provider_stations: any[];
  get_provider_details: () => void;
}
const badge_icon = {
  display: 'flex',
  width: '70px',
  justifyContent: 'start',
  alignItems: 'center',
  opacity: '0.6',
  color: '#F0F0F0',
}

const MQTTConfiguration: FC<MQTTConfigurationProps> = ({ provider_details, provider_stations, all_ground_stations, get_provider_details }) => {

  const [openMQTTConfigModal, setOpenMQTTConfigModal] = useState<any>(false);
  const [showDetails, setShowDetails] = useState<any>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState<any>(false);

  useEffect(() => {
    if (provider_details?.['MQTTTopicList']) {
      let topicSatIds = provider_details?.['MQTTTopicList']?.map(topic => topic?.['satelliteId'])
      setShowDetails(topicSatIds)
    }
  }, [provider_details?.['MQTTTopicList']])

  const remove_mqtt_topic = (topic_details) => {
    let filter_topics = provider_details?.['MQTTTopicList']?.filter(topic => topic?.['satelliteId'] !== topic_details?.['satelliteId'])
    let update_provider = {
      ...provider_details,
      MQTTTopicList: filter_topics,
      nextConfigStep: filter_topics?.length > 0 ? provider_details?.['nextConfigStep'] : 'Configure MQTT Topics',
      status: filter_topics?.length > 0 ? 'Configured' : 'Not Configured'
    }
    GroundStationService.updateGsProvider(provider_details?.['uniqueId'], update_provider).then((res) => {
      if (res.data) {
        toast.success('MQTT Topic Configuration deleted successfully.')
        get_provider_details()
      }
    }).catch((err) => {
      console.error(err)
      toast.error('Failed to remove MQTT Topic.')
    })
  }

  const toggle_details = (satellite_id) => {
    if (showDetails?.includes(satellite_id)) {
      setShowDetails(showDetails.filter(sat => sat !== satellite_id))
    } else {
      setShowDetails([...showDetails, satellite_id])
    }

  }


  return (
    <Fragment>
      {(provider_stations?.length === 0) &&
        <div className="gsp_badge_info gap-2 justify-content-center mt-5">
          <div className='d-flex align-items-center justify-content-center gap-3'>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M10 2.5C5.85938 2.5 2.5 5.85938 2.5 10C2.5 14.1406 5.85938 17.5 10 17.5C14.1406 17.5 17.5 14.1406 17.5 10C17.5 5.85938 14.1406 2.5 10 2.5ZM9.76562 14.375C9.61111 14.375 9.46006 14.3292 9.33159 14.2433C9.20311 14.1575 9.10298 14.0355 9.04384 13.8927C8.98471 13.75 8.96924 13.5929 8.99939 13.4413C9.02953 13.2898 9.10394 13.1506 9.2132 13.0413C9.32246 12.9321 9.46166 12.8577 9.61321 12.8275C9.76476 12.7974 9.92184 12.8128 10.0646 12.872C10.2074 12.9311 10.3294 13.0312 10.4152 13.1597C10.5011 13.2882 10.5469 13.4392 10.5469 13.5938C10.5469 13.801 10.4646 13.9997 10.3181 14.1462C10.1715 14.2927 9.97283 14.375 9.76562 14.375ZM11.0719 10.3906C10.4387 10.8156 10.3516 11.2051 10.3516 11.5625C10.3516 11.7075 10.2939 11.8466 10.1914 11.9492C10.0888 12.0518 9.94973 12.1094 9.80469 12.1094C9.65965 12.1094 9.52055 12.0518 9.41799 11.9492C9.31543 11.8466 9.25781 11.7075 9.25781 11.5625C9.25781 10.7066 9.65156 10.0262 10.4617 9.48203C11.2148 8.97656 11.6406 8.65625 11.6406 7.95195C11.6406 7.47305 11.3672 7.10938 10.8012 6.84023C10.668 6.77695 10.3715 6.71523 10.0066 6.71953C9.54883 6.72539 9.19336 6.83477 8.91953 7.05508C8.40312 7.4707 8.35938 7.92305 8.35938 7.92969C8.35591 8.0015 8.33834 8.07194 8.30766 8.13696C8.27697 8.20199 8.23379 8.26033 8.18055 8.30866C8.12732 8.357 8.06509 8.39437 7.99742 8.41866C7.92974 8.44294 7.85795 8.45366 7.78613 8.4502C7.71432 8.44673 7.64388 8.42916 7.57886 8.39848C7.51383 8.36779 7.45549 8.3246 7.40716 8.27137C7.35882 8.21814 7.32145 8.15592 7.29716 8.08824C7.27288 8.02057 7.26216 7.94877 7.26562 7.87695C7.26992 7.78203 7.33594 6.92695 8.2332 6.20508C8.69844 5.83086 9.29023 5.63633 9.99102 5.62773C10.4871 5.62188 10.9531 5.70586 11.2691 5.85508C12.2148 6.30234 12.7344 7.04805 12.7344 7.95195C12.7344 9.27344 11.8512 9.8668 11.0719 10.3906Z" fill="#EEEEEE" />
            </svg>
            <span>{`No ground stations added to this provider to configure MQTT Topic.`} </span>
          </div>
        </div>
      }
      {provider_stations?.length > 0 &&
        <div style={{ height: 'calc(100vh - 310px)', overflowY: 'auto' }}>
          {(!provider_details?.['MQTTTopicList'] || provider_details?.['MQTTTopicList']?.length === 0) &&
            <div
              className="add-mission-row mt-3"
              onClick={() => { setOpenMQTTConfigModal(true) }}
            >
              <i className="fe fe-plus fs-20  me-3"></i>
              MQTT Topics Configuration
            </div>
          }
          {(provider_details?.['MQTTTopicList'] && provider_details?.['MQTTTopicList']?.length > 0) &&
            <div className='position-absolute top-230px right-50'>
              <button className='mqtt_config_btn' onClick={() => { setOpenMQTTConfigModal(true) }}>
                <i className='fe fe-plus fs-16 color-text' /> Add
              </button>
            </div>
          }
          {(provider_details?.['MQTTTopicList'] && provider_details?.['MQTTTopicList']?.length > 0) &&
            provider_details?.['MQTTTopicList']?.map((topic, i) => {
              return (
                <div className={`d-fle flex-column gap-1 w-100 mqtt__topic__details__containe ${showDetails?.includes(topic?.['satelliteId']) && 'active border-0'}`} key={i}>
                  <div className='d-flex justify-content-between gap-4 py-3 align-items-center mqtt__topic__details__containe__header bg-transparent' onClick={() => { toggle_details(topic?.['satelliteId']) }}>
                    <div className='d-flex align-items-center gap-3'>
                      <div className='d-flex align-items-center gap-1 cursor-pointer' onClick={() => { toggle_details(topic?.['satelliteId']) }}
                      >
                        <i className={`fe fe-chevron-${showDetails?.includes(topic?.['satelliteId']) ? 'down' : 'right'} ${showDetails?.includes(topic?.['satelliteId']) ? 'color-atmos' : 'color_history'} fs-20`}></i>
                        <span className={`${showDetails?.includes(topic?.['satelliteId']) ? 'color-atmos' : 'color_history'} fs-16`}
                          style={{ fontWeight: 500 }}
                        >{topic?.['satelliteName']}</span>
                      </div>
                      <div className='h-5 border-left-2' />
                      <div className='d-flex align-items-center gap-2'>
                        <span className='fs-14 color_history'>Satellite ID:</span>
                        <span className='fs-14'>{topic?.['gspSatId']}</span>
                      </div>
                    </div>
                    <ButtonGroup size="sm" onClick={(e) => e.stopPropagation()}>
                      <div className="btn-list more-button">
                        <Dropdown drop="end">
                          <Dropdown.Toggle size="sm" variant="transparent" >
                            <i className="fe fe-more-vertical fs-20"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item eventKey="1"
                              onClick={(e) => {
                                setOpenMQTTConfigModal({
                                  topic: topic,
                                  mode: 'Update'
                                })
                              }}>
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="1"
                              onClick={(e) => {
                                setOpenDeleteModal(topic)
                              }}>
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </ButtonGroup>
                  </div>
                  {showDetails?.includes(topic?.['satelliteId']) &&
                    <div className='d-flex flex-column gap-2 w-100 '>
                      <div className='d-flex w-100 align-items-center py-2 px-5 rounded-3 justify-content-between gap-3'
                        style={{ border: '1px solid #B4B4B4' }}
                      >
                        <span className='fs-16 w-20 color-table-header-text '>TRANSCEIVER</span>
                        <span className='fs-16 w-30 color-table-header-text'>BAND ID</span>
                        <span className='fs-16 w-40 color-table-header-text'>MQTT TOPIC</span>
                        <span className='fs-16 w-10 color-table-header-text'>RM ID</span>
                      </div>
                      <div className='pb-2 px-0 m-0 gap-2 d-flex flex-column pop-scroll-sm'>
                        {topic?.['topic'] && topic?.['topic'].map((band, index) => {
                          return (
                            <div className='d-flex w-100 gs__details__containe' key={index} >
                              <div className='gs__details__containe__header py-2 px-5 d-flex w-100 align-items-center justify-content-between gap-3'>
                                <span title={band?.['name']} className='fs-14 w-20 color-table-header-text text-truncate'>
                                  {band?.['name']}
                                </span>
                                <div className='fs-12 w-30 gap-2 d-flex flex-column'>
                                  <div className='d-flex align-items-center w-100'>
                                    <span style={badge_icon}>Uplink</span>
                                    <span className='text-truncate flex-1' title={band?.['bandId']?.['uplink'] ? band?.['bandId']?.['uplink'] : '--'}>
                                      {band?.['bandId']?.['uplink'] ? band?.['bandId']?.['uplink'] : '--'}
                                    </span>
                                  </div>
                                  <div className='d-flex align-items-center w-100'>
                                    <span style={badge_icon}>Downlink</span>
                                    <span className='text-truncate flex-1' title={band?.['bandId']?.['downlink'] ? band?.['bandId']?.['downlink'] : '--'}>
                                      {band?.['bandId']?.['downlink'] ? band?.['bandId']?.['downlink'] : '--'}
                                    </span>
                                  </div>
                                </div>
                                <div className='fs-12 w-40 d-flex gap-2 flex-column'>
                                  <div className='d-flex align-items-center w-100'>
                                    <span style={badge_icon}>Uplink</span>
                                    <span className='text-truncate flex-1' title={band?.['mqttTopic']?.['uplink'] ? band?.['mqttTopic']?.['uplink'] : '--'}>
                                      {band?.['mqttTopic']?.['uplink'] ? band?.['mqttTopic']?.['uplink'] : '--'}
                                    </span>
                                  </div>
                                  <div className='d-flex align-items-center w-100'>
                                    <span style={badge_icon}>Downlink</span>
                                    <span className='text-truncate flex-1' title={band?.['mqttTopic']?.['downlink'] ? band?.['mqttTopic']?.['downlink'] : '--'}>
                                      {band?.['mqttTopic']?.['downlink'] ? band?.['mqttTopic']?.['downlink'] : '--'}
                                    </span>
                                  </div>
                                </div>
                                <div className='fs-12 w-10 d-flex gap-2 flex-column'>
                                  <div className='d-flex align-items-center w-100'>
                                    <span className='text-truncate flex-1' title={band?.['rmId'] ? band?.['rmId'] : '--'}>
                                      {band?.['rmId'] ? band?.['rmId'] : '--'}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  }
                </div>
              )
            })
          }

          {openMQTTConfigModal &&
            <MQTTConfigurationModal
              close={() => {
                setOpenMQTTConfigModal(false)
                get_provider_details()
              }}
              set_show_details={(satelliteId) => {
                setShowDetails([...showDetails, satelliteId])
              }}
              provider_ground_stations={all_ground_stations?.filter((groundStation) => provider_details?.['groundStations'].includes(groundStation?.['groundStationId']))}
              provider_details={provider_details}
              topic_details={openMQTTConfigModal}
            />
          }

          <Dialog
            onClick={(event) => {
              if (event) {
                remove_mqtt_topic(openDeleteModal)
              }
              setOpenDeleteModal(false)
            }}
            openModel={openDeleteModal} comment={`Are you sure you want to delete this MQTT Topics Configuration ?`}
          />

        </div>
      }
    </Fragment>
  )
}

export default MQTTConfiguration
